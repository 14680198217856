const gs = (_color?: string) => {
	var color = _color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 32 24"
			fill="none"
		>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M11.1152 2.15625C11.8298 2.15625 12.4091 2.59697 12.4091 3.14062V20.8594C12.4091 21.403 11.8298 21.8438 11.1152 21.8438C10.4006 21.8438 9.82127 21.403 9.82127 20.8594V3.14062C9.82127 2.59697 10.4006 2.15625 11.1152 2.15625ZM26.6425 4.125C27.3571 4.125 27.9364 4.56572 27.9364 5.10938V18.8906C27.9364 19.4343 27.3571 19.875 26.6425 19.875C25.9279 19.875 25.3485 19.4343 25.3485 18.8906V5.10938C25.3485 4.56572 25.9279 4.125 26.6425 4.125ZM16.291 6.09375C17.0056 6.09375 17.5849 6.53447 17.5849 7.07812V16.9219C17.5849 17.4655 17.0056 17.9062 16.291 17.9062C15.5763 17.9062 14.997 17.4655 14.997 16.9219V7.07812C14.997 6.53447 15.5763 6.09375 16.291 6.09375ZM5.93945 9.04688C6.65407 9.04688 7.23339 9.48759 7.23339 10.0312V13.9688C7.23339 14.5124 6.65407 14.9531 5.93945 14.9531C5.22482 14.9531 4.64551 14.5124 4.64551 13.9688V10.0312C4.64551 9.48759 5.22482 9.04688 5.93945 9.04688ZM21.4667 10.0312C22.1813 10.0312 22.7607 10.472 22.7607 11.0156V12.9844C22.7607 13.528 22.1813 13.9688 21.4667 13.9688C20.7521 13.9688 20.1728 13.528 20.1728 12.9844V11.0156C20.1728 10.472 20.7521 10.0312 21.4667 10.0312Z"
				fill={color}
			/>
		</svg>
	);
};

export default gs;
