import React, { useState } from "react";

import "./Icon.css";

type IconProps = {
	children: React.ReactNode;
	className?: string;
	hoverTip?: string;
	hideHoverTipArrow?: boolean;
	hoverPosition?: "bottom" | "top";
	disabled?: boolean;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const Icon: React.FC<IconProps> = ({
	className,
	hoverTip,
	hoverPosition = "bottom",
	...props
}) => {
	const [isHovering, setIsHovering] = useState<boolean>(false);

	const onMouseEnter = (e: any) => {
		setIsHovering(true);
	};

	const onMouseLeave = () => {
		setIsHovering(false);
	};

	const hoverTipComponent =
		isHovering && hoverTip && !props.disabled ? (
			<div
				className={`HoverTip ${hoverPosition} ${props.hideHoverTipArrow ? "hide-arrow" : "show-arrow"}`}
			>
				<div className="hovertip-text">{hoverTip}</div>
				{/* {shortcut ? <div className='hovertip-shortcut'>{shortcut}</div> : null} */}
			</div>
		) : null;

	return (
		<div
			className={`gigauser-Icon-parent ${props.disabled ? "icon-disabled" : "icon-active"}`}
		>
			<div
				className={`gigauser-Icon ${className} `}
				{...props}
				onMouseEnter={(e) => {
					onMouseEnter(e);
				}}
				onMouseLeave={onMouseLeave}
				onClick={
					props.disabled ? () => {} : props.onClick ? props.onClick : () => {}
				}
			>
				{props.children}
			</div>
			{hoverTipComponent}
		</div>
	);
};
export default Icon;
