/**
 * Mediaplayer docs: https://old.vidstack.io/docs/player/getting-started/installation
 */

import React, { useEffect, useRef, useState } from "react";
import "./Video.css";
import { GuideData, GuidePreview } from "../../../core/types/guide";
import { oembed } from "@loomhq/loom-embed";
import { OEmbedInterface } from "@loomhq/loom-embed/dist/.types/oembed";
import logger from "../../../utils/logger";
import playIcon from "../../../assets/svgs/playIcon";
import pauseIcon from "../../../assets/svgs/pauseIcon";
import logoIcon from "../../../assets/svgs/logoIcon";

import "vidstack/styles/defaults.css";
import "vidstack/styles/community-skin/video.css";
import {
	MediaCommunitySkin,
	MediaOutlet,
	useMediaStore,
	MediaPlayer,
	MediaPoster,
	MediaPlayButton,
	useMediaRemote,
} from "@vidstack/react";
import type {
	MediaPlayEvent,
	MediaPlayFailEvent,
	MediaPlayRequestEvent,
	MediaPauseEvent,
	MediaPlayerElement,
} from "vidstack";

import Icon from "../../../ui/Icon/Icon";
import useWindowSize from "../../../hooks/useWindowSize";
import { Device } from "../../../types/devices";
import { captureEvent } from "../../../core/analytics/analytics";
import { calculateMediaDuration } from "../../../core/canvas/canvasUtils/videoUtils";
import { useAppSelector } from "../../../redux";

type VideoProps = {
	video: GuideData["video"];
	videoSrc?: string;
	guidePreview?: GuidePreview;
	overrideDevice?: Device;
	hideVideoBranding?: boolean;
	embed?: boolean;
	disableDownload?: boolean;
};

const Video: React.FC<VideoProps> = (props) => {
	// const recorderType: "default" | "loom" | undefined = props.video.metadata?.recorderType;
	const vidRef = useRef<HTMLVideoElement>(null);
	const [button, setButton] = useState(true);
	const [videoHTML, setVideoHTML] = useState<string>("");

	const [firstPlay, setFirstPlay] = useState(false);
	const [flashPlay, setFlashPlay] = useState(false);
	const [flashPause, setFlashPause] = useState(false);

	const [subtitleSrc, setSubtitleSrc] = useState("");

	// ERROR ---- One or both of these two lines is causing error:
	const player = useRef<MediaPlayerElement>(null);
	const { paused } = useMediaStore(player);
	const remote = useMediaRemote(player);

	const [loaded, setLoaded] = useState(false);
	const [canPlay, setCanPlay] = useState(false);

	const [obfuscatedSource, setObfuscatedSource] = useState("");

	var device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;

	const handlePlay = () => {
		setButton(false);
		if (vidRef.current) {
			vidRef.current.play();
		}
	};

	const handlePause = () => {
		if (vidRef.current) {
			vidRef.current.pause();
		}
	};

	useEffect(() => {
		if (firstPlay) {
			captureEvent({
				eventName: "VideoPlayedInHelpCenter",
				value: { guide_id: props.guidePreview?.id as string },
			});
		}
	}, [firstPlay, props.guidePreview?.id]);

	const onMediaPlay = (event: MediaPlayEvent) => {
		if (!firstPlay) {
			setFirstPlay(true);
		}
		setFlashPlay(true);

		setTimeout(() => {
			setFlashPlay(false);
		}, 200);
	};

	const onMediaPause = (event: MediaPauseEvent) => {
		setFlashPause(true);

		setTimeout(() => {
			setFlashPause(false);
		}, 200);
	};

	useEffect(() => {
		logger.debug("vidref.current: ", vidRef.current);
		// logger.debug("src: ", props.video.metadata?.generatedVideo?.src)

		if (device === "mobile" && vidRef.current) {
			calculateMediaDuration(vidRef.current);
		}

	}, []);

	if (device === "laptop") {
		return (
			// <div>Hello there!</div>
			<div
				className={`gigauser-video-container 
                ${props.embed ? "gigauser-embed-video" : "gigauser-nonembed-video"}
                ${canPlay ? "gigauser-canplay-video" : "gigauser-noplay-video"}
                
            `}

				// style={{display: loaded?"block":"none"}}
				// onClick={button?handlePlay:undefined}
			>
				{/* <div
                    style={{visibility: (button)?"visible":"hidden"}} 
                    className={`gigauser-play-button`}></div> */}

				<MediaPlayer
					onPlay={onMediaPlay}
					onPause={onMediaPause}
					ref={player}
					title={props.guidePreview?.header.name}
					src={
						props.videoSrc || props.video.originalSrc
					}
					poster="https://media-files.vidstack.io/poster.png"
					className={`
                        ${!firstPlay ? "thumbnail-preview" : ""} 
                        ${device === "laptop" ? "gigauser-laptop-mediaplayer" : "gigauser-mobile-mediaplayer"}
                        ${props.embed ? "gigauser-embed-mediaplayer" : ""}
                    `}
					style={
						{
							// display: loaded?"block":"none"
						}
					}
					onLoadedMetadata={() => {
						setLoaded(true);
					}}
					onCanPlay={() => {
						setCanPlay(true);
					}}
					onContextMenu={(e: any) => {
						if (props.disableDownload) {
							e.preventDefault();
						}
					}}
					// poster="https://image.mux.com/VZtzUzGRv02OhRnZCxcNg49OilvolTqdnFLEqBsTwaxU/thumbnail.webp?time=268&width=980"
					// thumbnails="https://media-files.vidstack.io/sprite-fight/thumbnails.vtt"
					// aspectRatio={16 / 9}
					// crossorigin=""
				>
					<div
						className={`thumbnail-play ${paused ? "video-paused" : "video-playing"}`}
						onClick={() => {
							remote.play();
						}}
						style={{
							display: !loaded ? "none" : undefined,
						}}
					>
						<div className="thumbnail-play-icon-container">
							<div className="thumbnail-play-bg"></div>
							<div className="thumbnail-play-icon">{playIcon("white")}</div>
						</div>

						{!props.hideVideoBranding ? <CanvasPlayerCluesoPreview /> : null}
					</div>

					<MediaOutlet>
						{/* <MediaPoster alt = {props.guidePreview?.header.name} /> */}

						<div
							className={`flash-play ${flashPlay || flashPause ? "flash-show" : ""}`}
						>
							{flashPlay ? (
								<Icon className="flash-icon-container">
									{playIcon("white")}
								</Icon>
							) : flashPause ? (
								<Icon className="flash-icon-container">
									{pauseIcon("white")}
								</Icon>
							) : null}
						</div>

						{props.video.generated?.subtitles && (
							<track
								label="English"
								kind="subtitles"
								srcLang="en"
								src={props.video.generated?.subtitles}
								// default
							/>
						)}
					</MediaOutlet>

					<MediaCommunitySkin />
				</MediaPlayer>
			</div>
		);
	} else {
		return (
			<video
				ref={vidRef}
				preload="metadata"
				controls
				crossOrigin="anonymous"
				className={`gigauser-editor-video`}
				src={
					props.videoSrc || props.video.originalSrc
				}
				// onPlay={handlePlay}
				// onPause={handlePause}s
				onContextMenu={(e: any) => {
					if (props.disableDownload) {
						e.preventDefault();
					}
				}}
				controlsList="nodownload"
			>
				{props.video.generated?.subtitles && (
					<track
						label="English"
						kind="subtitles"
						srcLang="en"
						src={props.video.generated?.subtitles}
					/>
				)}
			</video>
		);
	}

	// else if (recorderType === "loom") {
	// 	return (
	// 		<div dangerouslySetInnerHTML={{ __html: videoHTML }}></div>
	// 	)
	// } else {
	//     return <></>;
	// }
};

type CanvasPlayerCluesoPreviewProps = {};

const CanvasPlayerCluesoPreview: React.FC<
	CanvasPlayerCluesoPreviewProps
> = () => {
	return (
		<a
			className="thumbnail-clueso-preview"
			href="https://www.clueso.io/features/video-tutorials"
			target="_blank"
		>
			<div className="thumbnail-clueso-text">Made in</div>
			<div className="thumbnail-clueso-logo">{logoIcon()}</div>
			{/* <img className="thumbnail-clueso-logo"></img> */}
		</a>
	);
};

export default Video;
