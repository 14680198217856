import React, { ChangeEvent, useEffect, useRef, useState } from "react";

// import logo from '@gigauser/common/src/assets/logos/Logo.svg';

import "./Viewer.css";
import HorizontalTabs from "../../../ui/tabs/_legacy/HorizontalTabs/HorizontalTabs";
// import PlainDoc from "../../formats/PlainDoc/PlainDoc";
import Video from "../../formats/Video/Video";
import Icon from "../../../ui/Icon/Icon";
import "../../../styles/animations/shake.css";
import { Guide, Organization } from "@gigauser/common/src/core/types/guide";

import downArrowheadIcon from "@gigauser/common/src/assets/svgs/downArrowheadIcon";
import { TextEditor } from "../../formats/RichText/TextEditor";

import closeIcon from "../../../assets/svgs/closeIcon";
import TourViewer from "../../formats/TourViewer/TourViewer";
import { Device } from "../../../types/devices";
import useWindowSize from "../../../hooks/useWindowSize";
import "./Viewer.css";
import { getBrandingColor } from "../../../core/utils/styleUtils";
import readIcon from "../../../assets/svgs/readIcon";
import watchIcon from "../../../assets/svgs/watchIcon";
import SimpleTabs from "../../../ui/tabs/SimpleTabs/SimpleTabs";
import tourIcon from "../../../assets/svgs/tourIcon";

import { useLocation, useSearchParams } from "react-router-dom";
import { Navigate } from "../../../help-center/HelpCenter/HelpCenter";
import { Size } from "../../../types/sizes";
import { HelpCenterConfig, Id } from "@giga-user-fern/api/types/api";
import { captureEvent } from "../../../core/analytics/analytics";
import CanvasProvider from "../../../core/canvas/CanvasProvider";
import dummyVideoEdits from "../../../core/canvas/videoEditTypes/dummyVideoEdits";
import { VideoEdits } from "@giga-user-fern/api/types/api/resources/video";
import logoIcon from "../../../assets/svgs/logoIcon";
import orgSetupData from "../../../setup/orgSetupData";

export type HelpCenterEnvironment = "remote-script" | "ext-hc";

type ViewerProps = {
	guide?: Guide; //If guide is not passed, then loading viewer will be shown.
	onClose: () => void;
	onBack?: () => void;
	overrideDevice?: Device;
	navigator?: () => Navigate;
	size?: Size;
	appTheme?: "gigauser-light" | "gigauser-dark";
	contentOnly?: boolean;
	environment: HelpCenterEnvironment;

	onOpenGuideFromId: (x: Id) => void;
	getOrganization: () => Organization | null;

	showCreator?: boolean;
	helpCenterConfig?: HelpCenterConfig;
};

type ViewerTab = "Article" | "Video";

const min = 1 / 3;
const mid = 2 / 3;
const max = 3 / 3;

enum CsatFeedback {
	min,
	mid,
	max,
}

export const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {
	var guide: Guide | undefined;
	if (props.guide) guide = props.guide;

	var device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;
	const gigDevice = "gigauser-" + device;

	const [position, setPosition] = useState<"left" | "right">("right");

	const [csatFeedback, setCsatFeedback] = useState<CsatFeedback>();
	const [messageFeedbackDone, setMessageFeedbackDone] = useState(false);
	const [messageFeedback, setMessageFeedback] = useState("");

	if (props.navigator) {
		var [searchParams, setSearchParams] = useSearchParams();
	}

	const loading = props.guide ? false : true;

	const submitCsatFeedback = (feedback: CsatFeedback) => {
		setCsatFeedback(feedback);
		captureEvent({
			eventName: "GuideRatedInHelpCenter",
			value: { guide_id: guide?.id as string, rating: feedback },
		});
	};

	const submitMessageFeedback = () => {
		setMessageFeedbackDone(true);
		captureEvent({
			eventName: "GuideFeedbackGivenInHelpCenter",
			value: { guide_id: guide?.id as string, feedback: messageFeedback },
		});
	};

	const onChangeMessageFeedback = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setMessageFeedback(e.target.value);
	};

	const closeHelpCenter = () => {
		props.onClose();
	};

	const brandColor = getBrandingColor();

	var tabs = [
		{
			text: "Video",
			icon: watchIcon,
			hidden: props.guide?.guideData.settings.publishedVideo !== "none",
		},
		{
			text: "Article",
			icon: readIcon,
			hidden: props.guide?.guideData.settings.hideArticle,
		},
	];

	var videoSrc = props.guide?.guideData.video.publishedVideoSrc || "";

	if (props.guide?.guideData.settings.publishedVideo !== "ai") {
		videoSrc = props.guide?.guideData.video.originalSrc || "";
	}

	// if(!videoSrc && isVideoVisible) videoSrc = props.guide?.guideData.video.originalSrc || ""

	const getFormatFromActiveTab = () => {
		if (!props.guide) return <h1>Couldn't get guide!</h1>;

		const articleComponent = (
			<div
				className={`gigauser-guideviewer-text-container ${device === "laptop" ? "gigauser-text-laptop" : "gigauser-text-mobile"}`}
			>
				<TextEditor
					onOpenGuideFromId={props.onOpenGuideFromId}
					type={"helpCenter"}
					size={props.size}
					initialContent={props.guide.guideData.plainDoc.data as any}
					editable={false}
					appTheme={props.appTheme}
				/>

				{window.location.href.includes("share/") ? (
					<a
						className="gigauser-viewer-branding"
						href="https://clueso.io"
						target="_blank"
					>
						<p>Created on</p>
						<div className="gigauser-viewer-logo">{logoIcon()}</div>
					</a>
				) : null}
			</div>
		);
		const videoComponent = (
			<Video
				disableDownload={disableDownload}
				hideVideoBranding={hideVideoBranding}
				guidePreview={props.guide.guidePreview}
				video={props.guide.guideData.video}
				videoSrc={videoSrc}
				overrideDevice={props.overrideDevice}
			/>
		);

		// if(props.size==="l"){
		return (
			<>
				{videoSrc &&
					props.guide.guideData.settings.publishedVideo !== "none" ? (
					<div className="Viewer-l-video">{videoComponent}</div>
				) : null}

				{props.guide.guideData.settings.hideArticle ? null : articleComponent}
			</>
		);
		// }
	};

	const changeTab = (tab: ViewerTab) => {
		if (props.navigator) {
			try {
				// Set the new value for the "format" parameter in the search parameters
				searchParams.set("format", tab);

				// Update the search parameters
				setSearchParams(searchParams);
			} catch (e) {
				console.error("format set tab: ", e);
			}
		}
	};

	var hideFeedback = false;
	var hideVideoBranding = false;
	var disableDownload = false;

	var feedbackText = "Did you find this article helpful?";
	var feedbackEnterFeedbackTitle = "Thank you! How can we help you better?";
	var feedbackEnterFeedbackPlaceholder = "Enter your feedback here ... ";
	var feedbackSubmitText = "Submit";
	var feedbackResultText = "Thank you for submitting feedback!";
	var createdByText = "Created by ";
	var descriptionColorFlip = false;

	const orgId = props.getOrganization()?.id;
	if (orgId) {
		hideFeedback = !props.helpCenterConfig?.feedbackFlag || false;
		hideVideoBranding = !props.helpCenterConfig?.videoBrandingFlag || false;
		disableDownload = props.helpCenterConfig?.disableDownload || false;

		feedbackText = props.helpCenterConfig?.feedbackPrompt || feedbackText;
		feedbackEnterFeedbackPlaceholder =
			props.helpCenterConfig?.feedbackPlaceholder ||
			feedbackEnterFeedbackPlaceholder;
		feedbackEnterFeedbackTitle =
			props.helpCenterConfig?.feedbackQuestion || feedbackEnterFeedbackTitle;
		feedbackSubmitText =
			props.helpCenterConfig?.feedbackSubmitText || feedbackSubmitText;
		feedbackResultText =
			props.helpCenterConfig?.feedbackRevertText || feedbackResultText;
		createdByText = props.helpCenterConfig?.createdByKeyword || createdByText;

		if (orgSetupData[orgId])
			descriptionColorFlip =
				orgSetupData[orgId].descriptionColorInvert ?? false;
	}

	const parts = props.guide?.guidePreview.header.description?.split(/(#[\w]+)/);

	return (
		<div className={`gigauser-guide-viewer-container ${gigDevice}`}>
			{props.onBack ? (
				<div className={`gigauser-viewer-header ${gigDevice}`}>
					<div
						className={`gigauser-viewer-back ${gigDevice}`}
						onClick={props.onBack}
					>
						<Icon className={`gigauser-viewer-back-button ${gigDevice}`}>
							{downArrowheadIcon(device === "laptop" ? brandColor : "white")}
						</Icon>

						{device === "laptop" ? (
							<span className="gigauser-back-text">Back</span>
						) : null}

						{device === "mobile" && props.guide ? (
							<div className="gigauser-header-title">
								{props.guide.guidePreview.header.name}{" "}
							</div>
						) : null}
					</div>

					<Icon
						className="gigauser-viewer-close-helpcenter"
						onClick={closeHelpCenter}
						style={{
							visibility: device === "laptop" ? "hidden" : "visible",
						}}
					>
						{closeIcon(device === "laptop" ? brandColor : "white")}
					</Icon>
				</div>
			) : null}

			<div
				className={`gigauser-viewer-body ${props.contentOnly ? "gigauser-content-only" : ""} style-scroll-square ${gigDevice}`}
			>
				{props.contentOnly ? null : (
					<div
						className={`gigauser-viewer-title ${loading ? "gigauser-loading-title" : "gigauser-not-loading"}`}
					>
						<div
							className={`gigauser-viewer-title-text gigauser-name ${props.size}`}
						>
							{props.guide
								? props.guide.guidePreview.header.name
								: "Random guide name here"}
						</div>

						<div
							className={`gigauser-viewer-title-text 
                            ${loading ? "" : "gigauser-description"}
                            ${descriptionColorFlip ? "color-flip" : ""}
                        ${props.size}`}
						>
							{parts?.map((part, index) =>
								part.startsWith("#") ? (
									<span
										key={index}
										className={`CollectionViewer-description-tag ${descriptionColorFlip ? "color-flip" : ""}`}
									>
										{part}
									</span>
								) : (
									<span
										key={index}
										className={`CollectionViewer-description-non-tag ${descriptionColorFlip ? "color-flip" : ""}`}
									>
										{part}
									</span>
								),
							)}
						</div>

						{props.guide?.guidePreview.author && props.showCreator && (
							<div className="Viewer-creator">
								{createdByText}
								<a
									href={`mailto: ${props.guide.guidePreview.author.email}`}
									className="Viewer-creator-email"
								>
									{props.guide?.guidePreview.author.email}
								</a>
								{/* {props.guide?.guideData.} */}
							</div>
						)}
					</div>
				)}

				<div
					className="gigauser-viewer-tabs"
					style={{
						visibility: props.guide ? "visible" : "hidden",
					}}
				>
					{/* {
                props.size!=="l"?
                <SimpleTabs
                    // tabs={["Tour", "Read", "Watch"]}
                    // tabs={guideData.tourEnabled ? ["Tour", "Read", "Watch"]:["Read", "Watch"]}
                    tabs = {tabs}
                    activeTab={activeTab}
                    setActiveTab={changeTab as (t:string) => void}
                />
                :null
            }
             */}
				</div>

				{props.guide ? (
					<div className="gigauser-guide-container style-scroll">
						{getFormatFromActiveTab()}

						{device === "laptop" && !hideFeedback ? (
							<div
								className={`gigauser-guide-feedback-container  ${device === "laptop" ? "gigauser-text-laptop" : "gigauser-text-mobile"}`}
							>
								<div className={`gigauser-guide-feedback`}>
									{messageFeedbackDone ? (
										<div>{feedbackResultText}</div>
									) : (
										<>
											<div className="gigauser-feedback-1">
												<div>{feedbackText}</div>

												<div className="gigauser-emojis-container">
													<div
														className={`gigauser-feedback-emoji ${csatFeedback === min ? "gigauser-active-emoji" : csatFeedback ? "gigauser-inactive-emoji" : "gigauser-regular-emoji"}`}
														onClick={
															csatFeedback
																? undefined
																: () => {
																	submitCsatFeedback(min);
																}
														}
													>
														{" "}
														<span>😞</span>
													</div>
													<div
														className={`gigauser-feedback-emoji ${csatFeedback === mid ? "gigauser-active-emoji" : csatFeedback ? "gigauser-inactive-emoji" : "gigauser-regular-emoji"}`}
														onClick={
															csatFeedback
																? undefined
																: () => {
																	submitCsatFeedback(mid);
																}
														}
													>
														{" "}
														<span>😐</span>
													</div>
													<div
														className={`gigauser-feedback-emoji ${csatFeedback === max ? "gigauser-active-emoji" : csatFeedback ? "gigauser-inactive-emoji" : "gigauser-regular-emoji"}`}
														onClick={
															csatFeedback
																? undefined
																: () => {
																	submitCsatFeedback(max);
																}
														}
													>
														{" "}
														<span>😃</span>
													</div>
												</div>
											</div>
											{csatFeedback ? (
												<div className="gigauser-feedback-2">
													<div>{feedbackEnterFeedbackTitle}</div>

													<textarea
														className="gigauser-feedback-textarea"
														value={messageFeedback}
														onChange={onChangeMessageFeedback}
														placeholder={feedbackEnterFeedbackPlaceholder}
													></textarea>

													<div
														className="gigauser-feedback-submit"
														onClick={submitMessageFeedback}
													>
														{feedbackSubmitText}
													</div>
												</div>
											) : null}
										</>
									)}
								</div>
							</div>
						) : null}
					</div>
				) : (
					<div className="gigauser-guide-container gigauser-loading-guide style-scroll">
						<div className="gigauser-loading-p"> This is a random step 1 </div>
						<div className="gigauser-loading-img">
							{" "}
							This is a random loading img
						</div>
						<div className="gigauser-loading-p"> This is a random step 1 </div>
						<div className="gigauser-loading-img">
							{" "}
							This is a random loading img
						</div>
					</div>
				)}
			</div>
		</div>
	);
};
