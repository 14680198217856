import React, { useState } from "react";
import "./HelpCenterHomeTopbar.css";
import Logo from "@gigauser/common/src/assets/logos/Logo.svg";
import searchIcon from "@gigauser/common/src/assets/svgs/searchIcon";
import { search } from "@giga-user-fern/api/types/api/resources/guides/resources/guideQueries";
import Icon from "@gigauser/common/src/ui/Icon/Icon";
import { useAppDispatch } from "../../../../redux";
import { setSearchVisible } from "../../../../redux/slices/hcUiSlice";
import { GuidePreview } from "@gigauser/common/src/core/types/guide";
import useRunDebouncedSearch from "../../../../hooks/useRunDebouncedSearch";
import LoadingRing from "@gigauser/common/src/assets/gifs/LoadingRing/LoadingRing";
import { helpSaver as saver } from "@gigauser/common/src/network/helpcenterSaver";
import orgSetupData, {
	getPageTitle,
	getTopbarTitle,
} from "@gigauser/common/src/setup/orgSetupData";

type HelpCenterHomeTopbarProps = {
	loadingSearch: boolean;
	setLoadingSearch: React.Dispatch<React.SetStateAction<boolean>>;
	setSearchResults: React.Dispatch<React.SetStateAction<GuidePreview[]>>;
};

const HelpCenterHomeTopbar: React.FC<HelpCenterHomeTopbarProps> = (props) => {
	const [searchInput, setSearchInput] = useState("");

	const runDebouncedSearch = useRunDebouncedSearch();

	const setLoading = (b: boolean) => {
		props.setLoadingSearch(b);
	};

	const setResults = (g: GuidePreview[]) => {
		props.setSearchResults(g);
	};

	const onChangeSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInput(e.target.value);

		runDebouncedSearch({
			searchString: e.target.value,
			setLoading,
			setResults,
		});
	};

	if (saver.organization?.id && orgSetupData[saver.organization.id]) {
		var searchTitle = orgSetupData[saver.organization?.id].searchTitle;
	}

	return (
		<div className="HelpCenterHomeTopbar">
			<div className="HelpCenterHomeTopbar-top">
				<div className="HelpCenterHomeTopbar-top-left">
					<img
						className="brand-logo"
						src={`https://clueso-public-assets.s3.ap-south-1.amazonaws.com/${saver.organization?.id}.svg`}
					/>
					<div>{getTopbarTitle(saver.organization)}</div>
				</div>

				<div className="HelpCenterHomeTopbar-top-right">
					<div>Log in to Scrut</div>
					<div>Contact Us</div>
				</div>
			</div>

			<div className="HelpCenterHomeTopbar-title">
				Hi, how can we help today?
			</div>

			<div className="HelpCenterHomeTopbar-search">
				{props.loadingSearch ? (
					<div className="searchIcon">
						<LoadingRing />
					</div>
				) : (
					<Icon className="searchIcon">{searchIcon("white")}</Icon>
				)}

				<input
					onChange={onChangeSearchInput}
					value={searchInput}
					className="HelpCenterHomeTopbar-search-input"
					placeholder={searchTitle || "Search for help ..."}
				/>
			</div>
		</div>
	);
};
export default HelpCenterHomeTopbar;
