const aiRewrite = (_color?: string) => {
	var color = _color || "#000000";

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 20 19"
			fill="none"
		>
			<path
				d="M1 17.9412C1.30464 16.9926 1.83667 15.7703 2.16195 14.9304M19 1.26902C8.96336 4.27673 5.11417 7.30774 2.16195 14.9304M19 1.26902C9.00889 -0.308559 0.187776 5.1562 2.16195 14.9304M19 1.26902C18.1177 3.35831 17.1097 5.03872 15.8643 6.48679C15.6446 6.74217 15.3054 6.85724 14.9731 6.8025L14.4268 6.71252C13.8071 6.61046 13.3501 7.2781 13.6694 7.81885V7.81885C13.8521 8.12817 13.7872 8.52501 13.5069 8.7497C10.8219 10.9021 7.23418 12.6561 2.16195 14.9304"
				stroke={color}
			/>
			<path
				d="M3.36475 17.4539H17.7566"
				stroke={color}
				stroke-linecap="round"
			/>
		</svg>
	);
};

export default aiRewrite;
