import { Organization } from "../../types/guide";
import { getScaleFactor } from "./canvasUtils";
import { printText } from "./canvasText";
import orgSetupCanvas from "../orgSetupCanvas";
import { Cover } from "@giga-user-fern/api/types/api";
import { getFont } from "../../../utils/fontsUtils";

type IntroOptions = {
	width: number;
	height: number;
	logo: HTMLImageElement | null;
	background: HTMLImageElement | null;
	organization: Organization | null;
};

export const drawIntro = async (
	ctx: CanvasRenderingContext2D,
	intro: Cover,
	options: IntroOptions,
): Promise<void> => {
	const { width, height, logo, background, organization } = options;

	if (!organization) return;

	ctx.clearRect(0, 0, 999999, 999999);

	if (intro.backgroundSrc && background) {
		ctx.drawImage(background, 0, 0, width, height);
	}

	const xPos = 120;

	const { f, wf, hf } = getScaleFactor(width, height);

	const customFont = orgSetupCanvas[organization.id]?.font;
	const splitChars = orgSetupCanvas[organization.id]?.maxCharsIntro;

	let fontColor = intro.fontColor || "#4f46e5";
	const textAlign = intro.alignment || "left";

	if (intro.showLogo && logo) {
		// ctx.clearRect(0, 0, 999999, 999999);
		const desiredHeight = intro.logoSize || 50;
		const desiredWidth = (logo.width / logo.height) * desiredHeight;
        try{
            ctx.drawImage(
                logo,
                xPos * wf,
                140 * hf,
                desiredWidth * f,
                desiredHeight * f,
            );
        }
        catch(err){
            console.error("Failed to draw logo in intro: ", err)
        }
		
	}

	const printIntroText = async () => {
		if (!intro.text1) return;
		let font = await getFont(true, intro.font);
		if (!font || typeof font == "boolean") throw new Error("Font not found");

		if (customFont !== undefined) {
			font = customFont;
		}

		let weight = 0;
		if (font.split("-").slice(-1)[0] === "regular") {
			weight = 500;
		} else {
			weight = parseInt(font.split("-").slice(-1)[0]);
		}
		if (Number.isNaN(weight)) {
			weight = 600;
		}

		printText(
			ctx,
			intro.text1,
			{ width, height },
			{
				color: fontColor,
				x: textAlign === "center" ? "center" : xPos * wf,
				y: "center",
				maxWidth: 1400 * wf,
				fontSize: 64 * f,
				fontWeight: weight,
				font: font,
				splitChars,
			},
		);
	};

	await printIntroText();
};

export const printIntroClipTitle = (
	ctx: CanvasRenderingContext2D,
	currTime: number,
	intro: Cover,
	options: IntroOptions,
): void => {
	const { width, height, organization } = options;

	if (!organization) return;

	const customFont = orgSetupCanvas[organization.id]?.font;
	const customColor = intro.fontColor;

	const { f, wf } = getScaleFactor(width, height);

	const startTime = intro.textStartTime;
	const endTime = undefined;

	let fillStyle = organization.brandColor || "#4f46e5";
	if (customColor) fillStyle = customColor;

	if (startTime && currTime < startTime) return;
	if (endTime && currTime > endTime) return;

	const printTitle = async () => {
		if (!intro.text1) return;

		let fontName = await getFont(true, intro.font);
		if (!fontName || typeof fontName == "boolean")
			throw new Error("Font not found");

		if (customFont !== undefined) {
			fontName = customFont;
		}

		printText(
			ctx,
			intro.text1,
			{ width, height },
			{
				color: fillStyle,
				x: "center",
				y: "center",
				maxWidth: 1400 * wf,
				fontSize: 64 * f,
				fontWeight: 600,
				font: fontName,
			},
		);
	};

	printTitle();
};
