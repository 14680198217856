import { Collection } from "@gigauser/common/src/core/types/collections";
import { CollectionNode, GuideNode, Tree } from "../../../redux/slices/hcSlice";

export const getCollectionNodeInTree = (tree: Tree, collection: Collection) => {
	// Goes through the tree, to try and return the collection if it is found
	const processTreeNode: (
		treeNode: CollectionNode | GuideNode,
	) => CollectionNode | null = (treeNode: CollectionNode | GuideNode) => {
		if (treeNode.type === "collection") {
			if (treeNode.collection.id === collection.id) {
				return treeNode;
			} else {
				// Return the or value for each child
				if (treeNode.children) {
					let found: null | CollectionNode = treeNode.children.reduce(
						(
							foundAlready: null | CollectionNode,
							x: CollectionNode | GuideNode,
						) => {
							return foundAlready ?? processTreeNode(x);
						},
						null,
					);
					return found;
				} else {
					return null;
				}
			}
		} else {
			return null;
		}
	};
	let found: null | CollectionNode = tree.reduce(
		(foundAlready: null | CollectionNode, x: CollectionNode | GuideNode) => {
			return foundAlready ?? processTreeNode(x);
		},
		null,
	);
	return found;
};

export const updateCollectionNodeInTree = (
	tree: Tree,
	collectionNode: CollectionNode,
) => {
	// Goes through the tree, to try and return the collection if it is found
	const processTreeNode: (
		treeNode: CollectionNode | GuideNode,
	) => CollectionNode | GuideNode = (treeNode: CollectionNode | GuideNode) => {
		if (treeNode.type === "collection") {
			if (treeNode.collection.id === collectionNode.collection.id) {
				return collectionNode;
			} else {
				if (treeNode.children) {
					treeNode.children = treeNode.children.map(processTreeNode);
					return treeNode;
				} else return treeNode;
			}
		} else {
			return treeNode;
		}
	};

	return tree.map(processTreeNode);
};
export const updateCollectionPathInTree = (
	tree: Tree,
	collectionNodePath: CollectionNode[],
) => {
	const treeCopy = JSON.parse(JSON.stringify(tree));
	const finalTree = collectionNodePath.reduce(
		(tree: Tree, collectionNode: CollectionNode) => {
			return updateCollectionNodeInTree(tree, collectionNode);
		},
		treeCopy,
	);
	return finalTree;
};
