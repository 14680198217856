import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
	BrowserRouter,
	RouterProvider,
	createBrowserRouter,
	useNavigate,
} from "react-router-dom";
import { routes } from "./routes";
import logger from "@gigauser/common/src/utils/logger";
import { Auth0Provider } from "@auth0/auth0-react";

const router = createBrowserRouter(routes);

logger.setLevel(process.env.NODE_ENV === "development" ? "debug" : "debug");

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement,
);

root.render(
	<React.StrictMode>
		<Auth0Provider
			domain="clueso-help.us.auth0.com"
			clientId="ce9DUs77JqpwcxUhzm3QTacuUrqDmfWB"
			authorizationParams={{
				audience: "http://localhost:8080",
				redirect_uri: window.location.origin,
			}}
			cacheLocation="localstorage"
		>
			<RouterProvider router={router} />
		</Auth0Provider>
	</React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.debug))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
