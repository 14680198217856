import { GigaUserApi } from "@giga-user-fern/api";
import {
	ApplySchemaAttributes,
	command,
	CommandFunction,
	composeTransactionSteps,
	CreateExtensionPlugin,
	EditorState,
	extension,
	ExtensionPriority,
	ExtensionTag,
	findMatches,
	FromToProps,
	getChangedRanges,
	GetMarkRange,
	getMarkRange,
	getMatchString,
	getSelectedWord,
	Handler,
	includes,
	isAllSelection,
	isElementDomNode,
	isMarkActive,
	isSelectionEmpty,
	isTextSelection,
	keyBinding,
	KeyBindingProps,
	last,
	LiteralUnion,
	MarkExtension,
	MarkExtensionSpec,
	MarkSpecOverride,
	NamedShortcut,
	NodeWithPosition,
	omitExtraAttributes,
	ProsemirrorAttributes,
	ProsemirrorNode,
	removeMark,
	Static,
	updateMark,
} from "@remirror/core";
import type { CreateEventHandlers } from "@remirror/extension-events";
import { undoDepth } from "@remirror/pm/history";
import { MarkPasteRule } from "@remirror/pm/paste-rules";
import { Selection } from "@remirror/pm/state";
import { ReplaceAroundStep, ReplaceStep } from "@remirror/pm/transform";

/**
 * Can be an empty string which sets url's to '//google.com'.
 */
export type DefaultProtocol = "http:" | "https:" | "" | string;

export interface FoundAutoLink {
	/** link href */
	href: string;
	/** link text */
	text: string;
	/** offset of matched text */
	start: number;
	/** index of next char after match end */
	end: number;
}

interface LinkWithProperties extends Omit<FoundAutoLink, "href"> {
	range: FromToProps;
	attrs: LinkAttributes;
}

interface EventMeta {
	selection: Selection;
	range: FromToProps | undefined;
	doc: ProsemirrorNode;
	attrs: LinkAttributes;
}

interface ShortcutHandlerActiveLink extends FromToProps {
	attrs: LinkAttributes;
}

export interface ShortcutHandlerProps extends FromToProps {
	selectedText: string;
	activeLink: ShortcutHandlerActiveLink | undefined;
}

type LinkTarget = LiteralUnion<
	"_blank" | "_self" | "_parent" | "_top",
	string
> | null;

export interface LinkOptions {
	onClick?: Handler<(event: MouseEvent, data: LinkClickData) => boolean>;
	onOpenGuideFromId: (x: GigaUserApi.Id) => boolean;
}

export interface LinkClickData extends GetMarkRange, LinkAttributes {}

export type LinkAttributes = ProsemirrorAttributes<{
	/**
	 * The link which is a required property for the link mark.
	 */
	href: string;

	/**
	 * True when this was an automatically generated link. False when the link was
	 * added specifically by the user.
	 *
	 * @defaultValue false
	 */
	auto?: boolean;

	/**
	 * The target for the link..
	 */
	target?: LinkTarget;
}>;

@extension<LinkOptions>({
	defaultOptions: {
		onOpenGuideFromId: () => {
			return false;
		},
	},
	staticKeys: [],
	handlerKeyOptions: { onClick: { earlyReturnValue: true } },
	handlerKeys: [],
	defaultPriority: ExtensionPriority.Medium,
})
export class ViewingLinkExtension extends MarkExtension<LinkOptions> {
	get name() {
		return "link" as const;
	}

	/**
	 * The autoLinkRegex option with the global flag removed, ensure no "lastIndex" state is maintained over multiple matches
	 * @private
	 */
	private _autoLinkRegexNonGlobal: RegExp | undefined = undefined;

	createTags() {
		return [ExtensionTag.Link, ExtensionTag.ExcludeInputRules];
	}

	createMarkSpec(
		extra: ApplySchemaAttributes,
		override: MarkSpecOverride,
	): MarkExtensionSpec {
		const AUTO_ATTRIBUTE = "data-link-auto";

		return {
			inclusive: false,
			excludes: "_",
			...override,
			attrs: {
				...extra.defaults(),
				href: {},
				target: { default: "_blank" },
				auto: { default: false },
				guideId: { default: null },
			},
			parseDOM: [
				{
					tag: "a[href]",
					getAttrs: (node) => {
						if (!isElementDomNode(node)) {
							return false;
						}

						const href = node.getAttribute("href");
						const text = node.textContent;

						// If link text content equals href value we "auto link"
						// e.g [test](//test.com) - not "auto link"
						// e.g [test.com](//test.com) - "auto link"
						const auto = false;

						return {
							...extra.parse(node),
							href,
							auto,
							target: "_blank",
						};
					},
				},
				...(override.parseDOM ?? []),
			],
			toDOM: (node) => {
				if (!node.attrs.guideId) {
					const {
						auto: _,
						target: __,
						...rest
					} = omitExtraAttributes(node.attrs, extra);
					const auto = node.attrs.auto ? { [AUTO_ATTRIBUTE]: "" } : {};
					const rel = "noopener noreferrer nofollow";
					const attrs = {
						...extra.dom(node),
						...rest,
						rel,
						...auto,
						target: "_blank",
					};

					return ["a", attrs, 0];
				} else {
					const {
						auto: _,
						target: __,
						...rest
					} = omitExtraAttributes(node.attrs, extra);
					const auto = node.attrs.auto ? { [AUTO_ATTRIBUTE]: "" } : {};
					const rel = "noopener noreferrer nofollow";
					const attrs = {
						...extra.dom(node),
						...rest,
						rel,
						...auto,
						target: "_self",
						href: "/guide/" + node.attrs.guideId,
					};
					return ["span", { class: "gigauser-helpcenter-text-link" }, 0];
				}
			},
		};
	}

	/**
	 * Track click events passed through to the editor.
	 */
	createEventHandlers(): CreateEventHandlers {
		return {
			clickMark: (event, clickState) => {
				const markRange = clickState.getMark(this.type);

				if (!markRange) {
					return;
				}

				const attrs = markRange.mark.attrs as LinkAttributes;
				const data: LinkClickData = { ...attrs, ...markRange };

				// If one of the handlers returns `true` then return early.
				if (
					data.guideId &&
					this.options.onOpenGuideFromId(GigaUserApi.Id(data.guideId as string))
				) {
					event.stopImmediatePropagation();
					return true;
				}

				// If editable is false, the openLinkOnClick handler or the selectTextOnClick handler should
				// not be triggered or it will conflict with the default browser event
				if (!this.store.view.editable) {
					return;
				}

				let handled = false;

				// if (this.options.openLinkOnClick) {
				//   handled = true;
				//   const href = attrs.href;
				//   window.open(href, '_blank');
				// }

				// if (this.options.selectTextOnClick) {
				//   handled = true;
				//   this.store.commands.selectText(markRange);
				// }

				return handled;
			},
		};
	}
}
