import { Organization } from "../../types/guide";
import { getScaleFactor } from "./canvasUtils";
import { printText } from "./canvasText";
import orgSetupCanvas from "../orgSetupCanvas";
import { Cover } from "@giga-user-fern/api/types/api";
import { getFont } from "../../../utils/fontsUtils";

type OutroOptions = {
	width: number;
	height: number;
	organization: Organization | null;
	background: HTMLImageElement | null;
	logo: HTMLImageElement | null;
};

export const drawOutro = async (
	ctx: CanvasRenderingContext2D,
	outro: Cover,
	options: OutroOptions,
): Promise<void> => {
	const { width, height, organization, background, logo } = options;

	if (!organization) return;

	ctx.clearRect(0, 0, width, height);

	if (outro.backgroundSrc && background) {
		ctx.drawImage(background, 0, 0, width, height);
	}

	let fillStyle = organization.brandColor || "#4f46e5";

	const customColor = orgSetupCanvas[organization.id]?.outroFontColor;
	const customFont = orgSetupCanvas[organization.id]?.font;

	if (outro.fontColor || customColor) {
		fillStyle = outro.fontColor || customColor || fillStyle;
	}

	const { f, wf, hf } = getScaleFactor(width, height);

	const printTextAsync = async (
		text: string | undefined,
		textY: number,
		fontSize: number,
	) => {
		if (!text) return;
		let font = await getFont(true, outro.font);
		if (!font || typeof font == "boolean") throw new Error("Font not found");

		if (customFont !== undefined) {
			font = customFont;
		}

		let weight = 0;
		if (font.split("-").slice(-1)[0] === "regular") {
			weight = 500;
		} else {
			weight = parseInt(font.split("-").slice(-1)[0]);
		}
		if (Number.isNaN(weight)) {
			weight = 600;
		}

		printText(
			ctx,
			text,
			{ width, height },
			{
				color: fillStyle,
				x: "center",
				y: textY,
				maxWidth: 1600 * wf,
				fontSize: fontSize * f,
				fontWeight: weight,
				font: font,
				maxChars: { portrait: 20, landscape: 30 },
			},
		);
	};

	const text1Y = outro.text2 ? 0.5 * height - 74 * hf : 0.5 * height;
	const text2Y = outro.text1 ? 0.5 * height + 74 * hf : 0.5 * height;

	if (outro.showLogo && logo) {
		const desiredHeight = outro.logoSize || 50;
		let logoY = 0.5 * height - 160 * hf;
		if (outro.text1 && outro.text2) logoY -= 140 * hf;

		const desiredWidth = (logo.width / logo.height) * desiredHeight;
        try{
            ctx.drawImage(
                logo,
                0.5 * (width - desiredWidth * f),
                logoY,
                desiredWidth * f,
                desiredHeight * f,
            );
        }
        catch(err){
            console.error("Failed to draw logo in outro: ", err)
        }
		
	}

	printTextAsync(outro.text1, text1Y, 36);
	printTextAsync(outro.text2, text2Y, 52);
};
