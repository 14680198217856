import React, { useEffect, useRef, useState, MutableRefObject } from "react";
import Icon from "../../../../../../ui/Icon/Icon";
// import { orderedList } from '../../../../../../assets/svgs/text-editor-toolbar/ol';
import "./ToolbarButton.css";
import downArrowheadIcon from "../../../../../../assets/svgs/downArrowheadIcon";
import Clickout from "../../../../../../layouts/Clickout/Clickout";

type ToolbarButtonProps = {
	active: () => boolean;
	handler: () => void;
	hoverTip?: string;
	source: JSX.Element;
	backgroundColor?: string;
	disabled?: boolean;

	dropdownMenu?: ToolbarButtonProps[];
};

const ToolbarButton: React.FC<ToolbarButtonProps> = (props) => {
	const [showDropdown, setShowDropdown] = useState(false);

	return (
		<Clickout
			closeFunction={() => {
				setShowDropdown(false);
			}}
		>
			<div
				onClick={
					props.disabled
						? () => {}
						: props.dropdownMenu
							? () => {
									setShowDropdown(!showDropdown);
								}
							: props.handler
				}
				className={`
                ToolbarButton ${props.active() ? "Toolbar-activeButton" : "Toolbar-inactiveButton"}
                ${props.disabled ? "Toolbar-disabled" : "Toolbar-nondisabled"}
                ${props.dropdownMenu ? "Toolbar-drop" : "Toolbar-nondrop"}
            `}
				style={{
					backgroundColor: props.backgroundColor
						? props.backgroundColor
						: undefined,
					borderRadius: props.backgroundColor ? 0 : undefined,
				}}
			>
				<Icon
					hoverTip={props.hoverTip}
					hideHoverTipArrow
					disabled={props.disabled}
					className={`gigauser-texteditor-toolbar-button ${props.active()}`}
				>
					{props.source}
				</Icon>

				{props.dropdownMenu && (
					<>
						<div className="ToolbarButton-down-arrow">
							{downArrowheadIcon("white")}
						</div>

						<div
							className="ToolbarButton-dropdown"
							style={{ display: showDropdown ? "block" : undefined }}
						>
							<div className="ToolbarButton-dropdown-list">
								{props.dropdownMenu.map((x) => (
									<ToolbarButton
										disabled={x.disabled}
										active={x.active}
										handler={x.handler}
										source={x.source}
									/>
								))}
							</div>
						</div>
					</>
				)}
			</div>
		</Clickout>
	);
};
export default ToolbarButton;
