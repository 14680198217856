/**
 * Cutton = "C"lueso + B"utton"
 * (A button for Clueso)
 */

import React, { useState } from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import "./Cutton.css";
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";
import { Size } from "../../../types/sizes";
import Icon from "../../Icon/Icon";

export type CuttonProps = {
	children?: string;
	onClick?: () => void;
	url?: string;
	rank?: "primary" | "secondary" | "tertiary" | "quaternary";
	theme?: "danger" | "pink";
	isLoading?: boolean;
	rightIcon?: React.ReactElement;
	leftIcon?: React.ReactElement;
	magicShadow?: boolean;
	rightArrow?: boolean;
	loadingText?: string;
	disabled?: boolean;
	size?: Size;
	betaLabel?: boolean;
	fullWidth?: boolean;
};

const Cutton: React.FC<CuttonProps> = ({ children, ...props }) => {
	const rank = props.rank || "primary";

	const disabled = props.isLoading || props.disabled;

	const content = (
		<>
			{children}
			{props.betaLabel ? <div className="Cutton-beta-label">BETA</div> : null}

			<div className="Cutton-right-icon-container">
				{props.rightArrow ? downArrowheadIcon("white") : null}
			</div>
		</>
	);

	const cutton = (
		<Button
			onClick={disabled ? undefined : props.onClick}
			color={"white"}
			className={`Cutton-Button ${rank}  ${props.theme}
                    ${props.magicShadow ? "magicShadow" : ""}
                    ${props.rightArrow ? "Cutton-rightArrow" : ""}
                    ${props.isLoading ? "Cutton-loading" : "Cutton-notloading"}
                    ${props.fullWidth ? "Cutton-fullWidth" : ""}
                `}
			leftIcon={props.leftIcon}
			rightIcon={props.rightIcon}
			isLoading={props.isLoading}
			loadingText={props.loadingText}
			isDisabled={disabled}
		>
			<div style={{ display: "inline-flex", alignItems: "center" }}>
				{content}
			</div>
		</Button>
	);

	if (props.url) {
		return (
			<a
				href={props.url}
				target="_blank"
				rel="noopener noreferrer"
				className={`Cutton ${props.size} ${props.fullWidth}`}
			>
				{cutton}
			</a>
		);
	} else
		return (
			<div className={`Cutton ${props.size} ${props.fullWidth}`}>{cutton}</div>
		);
};

export default Cutton;
