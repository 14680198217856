import React, { useContext, useEffect, useRef } from "react";
import "./HelpCenterViewer.css";
import { Guide, GuidePreview } from "@gigauser/common/src/core/types/guide";
import GridLoader from "@gigauser/common/src/ui/loaders/GridLoader/GridLoader";
import { Viewer } from "@gigauser/common/src/components/Guide/Viewer/Viewer";
import { Collection } from "@gigauser/common/src/core/types/collections";
import Breadcrumbs from "@gigauser/common/src/ui/tabs/BreadcrumbsReadonly/Breadcrumbs";
import CollectionViewer from "../../CollectionViewer/CollectionViewer";
import logger from "@gigauser/common/src/utils/logger";
import useOpenGuide from "../../../../hooks/useOpenGuide";
import useWindowSize from "@gigauser/common/src/hooks/useWindowSize";
import { indexbarThreshold, tocThreshold } from "../../layout";
import useOpenGuideFromId from "../../../../hooks/useOpenGuideFromId";
import ExtHelpCenterContext from "../../ExtHelpCenterContext";
import { getPageTitle } from "@gigauser/common/src/setup/orgSetupData";
import { useAppDispatch, useAppSelector } from "../../../../redux";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";

type HelpCenterViewerProps = {
	loading: boolean;
	viewOnly?: boolean;
	onClickCollectionBreadcrumbs: (collection: Collection) => Promise<void>;
	helpCenterConfig?: HelpCenterConfig;
};

const HelpCenterViewer: React.FC<HelpCenterViewerProps> = (props) => {
	const containerRef = useRef<HTMLDivElement>(null);

	const collectionPath = useAppSelector((state) => state.hc.collectionPath);

	const dispatch = useAppDispatch();
	const guide = useAppSelector((state) => state.hc.currentGuide);
	const onOpenGuideFromId = useOpenGuideFromId();
	const windowSize = useWindowSize();
	const appTheme = useAppSelector((state) => state.hcUi.theme);
	const helpCenterContext = useContext(ExtHelpCenterContext);

	useEffect(() => {
		if (guide) {
			const org = helpCenterContext.getOrganization();
			if (org)
				document.title = `${guide.guidePreview?.header.name} | ${getPageTitle(org, props.helpCenterConfig)}`;
		}
	}, [guide]);

	var isPrivate = false;

	if (props.viewOnly) isPrivate = false;
	else {
		if (collectionPath) {
			collectionPath.map((collection) => {
				if (collection.private) isPrivate = true;
			});

			if (guide) {
				if (!guide.guidePreview?.header.published) isPrivate = true;
			}
		}
	}

	const orgId = helpCenterContext.getOrganization()?.id;

	if (orgId) var showCreator = props.helpCenterConfig?.creatorVisibilityFlag;

	return (
		<div
			className={`HelpCenterViewer ${windowSize.width < indexbarThreshold ? "remove-margin" : ""}`}
			ref={containerRef}
			id="HelpCenterViewer"
		>
			<div
				className={`ViewerContainer ${guide ? "GuideViewer" : "CollectionViewer"} ${props.viewOnly ? "ViewOnly" : ""}`}
			>
				{collectionPath && !props.viewOnly ? (
					<div className="gigauser-helpcenter-breadcrumbs">
						<Breadcrumbs
							appTheme={appTheme}
							navigationHandler={props.onClickCollectionBreadcrumbs}
							segments={collectionPath}
						></Breadcrumbs>
					</div>
				) : null}

				{props.loading || !collectionPath ? (
					<GridLoader center />
				) : isPrivate ? (
					<div className="HelpCenter-private-container">
						This page is authorized for admins only.
					</div>
				) : guide ? (
					<Viewer
						showCreator={showCreator}
						guide={guide.guideData ? (guide as Guide) : undefined}
						// guide={undefined}
						onClose={() => { }}
						overrideDevice={"laptop"}
						size="l"
						appTheme={appTheme}
						// navigator={() => {}}
						onOpenGuideFromId={onOpenGuideFromId}
						getOrganization={helpCenterContext.getOrganization}
						// videoEdits={guide.videoEdits || undefined}
						environment="ext-hc"
						helpCenterConfig={props.helpCenterConfig}
					/>
				) : (
					<CollectionViewer helpCenterConfig={props.helpCenterConfig} />
				)}
			</div>

			<div
				className={`TocContainer ${windowSize.width < tocThreshold ? "hide" : ""}`}
			>
				{/* Contents */}
			</div>
		</div>
	);
};
export default HelpCenterViewer;
