import React, { useState } from "react";
import "./ThemeSwitch.css";

type ThemeSwitchProps = {
	onLight: () => void;
	onDark: () => void;
};

const ThemeSwitch: React.FC<ThemeSwitchProps> = (props) => {
	const [checked, setChecked] = useState(true);

	const onToggle = (e: any) => {
		if (checked) {
			props.onDark();
			setChecked(false);
		} else {
			props.onLight();
			setChecked(true);
		}
	};

	return (
		<div className="ThemeSwitch-wrapper">
			<input
				type="checkbox"
				id="hide-checkbox"
				onChange={onToggle}
				checked={checked}
			/>
			<label htmlFor="hide-checkbox" className="toggle">
				<span className="toggle-button">
					<span className="crater crater-1"></span>
					<span className="crater crater-2"></span>
					<span className="crater crater-3"></span>
					<span className="crater crater-4"></span>
					<span className="crater crater-5"></span>
					<span className="crater crater-6"></span>
					<span className="crater crater-7"></span>
				</span>
				<span className="star star-1"></span>
				<span className="star star-2"></span>
				<span className="star star-3"></span>
				<span className="star star-4"></span>
				<span className="star star-5"></span>
				<span className="star star-6"></span>
				<span className="star star-7"></span>
				<span className="star star-8"></span>
			</label>
		</div>
	);
};
export default ThemeSwitch;
