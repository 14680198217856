import { isDataURI } from "./cleanImageSrc";

export async function copyMarkdownWithImages(markdownString: string) {
	// Regular expression to match markdown images
	const imageRegex = /!\[([^\]]*)\]\((data:image\/[^;]+;[^,]+,[^)]+)\)/g;

	// Array to store clipboard items
	let clipboardItems = [];

	// Parse the markdown string and convert images to blobs
	let lastIndex = 0;
	let match;

	while ((match = imageRegex.exec(markdownString)) !== null) {
		// Add text from the last index to the start of the image markdown
		if (match.index > lastIndex) {
			const text = markdownString.substring(lastIndex, match.index);
			clipboardItems.push(
				new ClipboardItem({
					"text/plain": new Blob([text], { type: "text/plain" }),
				}),
			);
		}

		// Convert data URL to blob and add to clipboard items
		const dataURL = match[2];
		const blob = await (await fetch(dataURL)).blob();
		clipboardItems.push(new ClipboardItem({ [blob.type]: blob }));

		// Update the last index to after the current image markdown
		lastIndex = match.index + match[0].length;
	}

	// Add remaining text after the last image
	if (lastIndex < markdownString.length) {
		const text = markdownString.substring(lastIndex);
		clipboardItems.push(
			new ClipboardItem({
				"text/plain": new Blob([text], { type: "text/plain" }),
			}),
		);
	}

	// Write to clipboard
	try {
		await navigator.clipboard.write(clipboardItems);
		console.log("Markdown with images copied to clipboard!");
	} catch (err) {
		console.error("Failed to copy to clipboard: ", err);
	}
}

export function getDataUris(htmlString: string) {
	const parser = new DOMParser();
	const dataUri = [];
	const doc = parser.parseFromString(htmlString, "text/html");
	// Traverse and sanitize image elements
	const images = doc.querySelectorAll("img");
	for (let i = 0; i < images.length; i++) {
		const img = images[i];
		// Remove all attributes except src
		if (isDataURI(img.src)) dataUri.push(img.src);
	}
	return dataUri;
}

function replaceImagesInMarkdown(
	markdown: string,
	imageUrls: string[],
): string {
	// Regular expression to find Markdown image tags
	const regex = /!\[.*?\]\(.*?\)/g;

	// Replace each match with a new URL from the imageUrls array
	let index = 0;
	return markdown.replace(regex, () => {
		if (index < imageUrls.length) {
			const replacement = `![](${imageUrls[index]})`;
			index++;
			return replacement;
		} else {
			// If there are more images than URLs, keep the original image
			return "";
		}
	});
}

export function getHTMLandTextWithImages(
	htmlString: string,
	markdownString: string,
	hostedImages: string[],
) {
	// Parse the HTML string into a document
	const parser = new DOMParser();
	// console.log(htmlString)
	const doc = parser.parseFromString(htmlString, "text/html");
	// Traverse and sanitize image elements
	const images = doc.querySelectorAll("img");
	for (let i = 0; i < images.length; i++) {
		const img = images[i];
		// Remove all attributes except src
		const attributes = Array.from(img.attributes);
		for (let j = 0; j < attributes.length; j++) {
			const attr = attributes[j];
			if (attr.name !== "src" && attr.name !== "width") {
				console.log("Removing attribute: ", attr.name);
				img.removeAttribute(attr.name);
			}
		}
		if (!img.getAttribute("width")) {
			img.setAttribute("style", "width: 100%;");
		} else {
			const width = img.getAttribute("width");
			const widthString = width?.endsWith("px") ? width : width + "px";
			img.removeAttribute("width");
			img.setAttribute("style", `width: ${widthString}; height: auto;`);
		}
		// img.alt = "IMAGE HERE"
		img.src = hostedImages[i];
	}
	doc.querySelectorAll("p").forEach((p) => {
		// Clean the attributes of the node
		// cleanAttributes(p);

		// Create a wrapper div and insert it before the paragraph
		const wrapper = doc.createElement("div");
		p.before(wrapper);
		// p.setAttribute("style", "box-sizing: border-box; margin: 1.2em 0px;")
		// Move the paragraph inside the wrapper div
		wrapper.appendChild(p);
	});

	// Extract the sanitized HTML and copy it as a single ClipboardItem
	const sanitizedHTML = doc.body.innerHTML;
	let plainText = "";
	const processNode = (node: any) => {
		if (node.tagName && node.tagName.toLowerCase() === "img") {
			// For each image, append its alt text
			plainText += "\n\n" + (node.alt || "") + "\n\n";
		} else {
			// For other nodes, get their text content
			if (node.tagName && node.tagName.toLowerCase() === "div") {
				plainText += node.textContent || node.innerText || "";
			}
		}
		node.childNodes.forEach((x: any) => processNode(x));
	};

	doc.body.childNodes.forEach(processNode);
	//TODO: Add raw markdown output and raw html output. Both of those options should trigger a download
	//to system with the file.
	const markdownOutput = replaceImagesInMarkdown(markdownString, hostedImages);
	// No assets option at the moment

	return { htmlOutput: sanitizedHTML, textOutput: plainText, markdownOutput };
}

export const copyArticleToClipboard = async (html: string, text: string) => {
	try {
		await navigator.clipboard.write([
			new ClipboardItem({
				"text/html": new Blob([html], { type: "text/html" }),
				"text/plain": new Blob([text], { type: "text/plain" }),
			}),
		]);
		console.log("Sanitized HTML with images copied to clipboard!");
	} catch (err) {
		console.error("Failed to copy to clipboard: ", err);
	}
};

export const copyMarkdownToClipboard = async (markdown: string) => {
	try {
		await navigator.clipboard.write([
			new ClipboardItem({
				"text/plain": new Blob([markdown], { type: "text/plain" }),
			}),
		]);
		console.log("Markdown copied to clipboard!");
	} catch (err) {
		console.error("Failed to copy to clipboard: ", err);
	}
};

export const copyRawHTMLToClipboard = async (html: string) => {
	try {
		await navigator.clipboard.write([
			new ClipboardItem({
				"text/plain": new Blob([html], { type: "text/plain" }),
			}),
		]);
		console.log("Raw HTML copied to clipboard!");
	} catch (err) {
		console.error("Failed to copy to clipboard: ", err);
	}
};

export function downloadStringAsFile(
	content: string,
	fileName: string,
	contentType: string,
) {
	// Step 1: Create a Blob from the string
	const blob = new Blob([content], { type: contentType });

	// Step 2: Create a URL for the Blob
	const url = window.URL.createObjectURL(blob);

	// Step 3: Create an anchor (<a>) element
	const downloadLink = document.createElement("a");
	downloadLink.href = url;
	downloadLink.download = fileName;

	// Step 4: Trigger the download
	document.body.appendChild(downloadLink);
	downloadLink.click();
	document.body.removeChild(downloadLink);

	// Step 5: Cleanup
	window.URL.revokeObjectURL(url);
}

export function srtToWebVTT(srtData: string): string {
	// Add the 'WEBVTT' header necessary for WebVTT files
	let webVTTData: string = "WEBVTT\n\n";

	// Split the input data into blocks by double newlines
	const blocks = srtData.split("\n\n");

	for (let block of blocks) {
		// Skip empty blocks
		if (!block.trim()) continue;

		// Split each block into lines
		const lines = block.split("\n");

		// Iterate through lines, skipping the numerical identifier
		for (let i = 1; i < lines.length; i++) {
			let line = lines[i];

			// Convert timestamps from SRT format (00:01:22,500) to WebVTT format (00:01:22.500)
			if (line.includes("-->")) {
				line = line.replace(/,/g, ".");
			}

			// Add the line to the WebVTT data
			webVTTData += line + "\n";
		}

		// Add an extra newline after each block for correct formatting
		webVTTData += "\n";
	}

	return webVTTData;
}

// Usage example
