const hrIcon = (color = "#000000") => {
	return (
		<svg version="1.1" width="100%" height="100%" viewBox="0 0 290.658 290.658">
			<g>
				<g>
					<rect y="139.474" width="290.658" height="11.711" fill={color} />
				</g>
			</g>
		</svg>
	);
};

export default hrIcon;
