import React, { useState } from "react";
import "./PasswordPrompt.css";

interface PasswordPromptProps {
	submit: (password: string) => Promise<boolean>;
}

const PasswordPrompt: React.FC<PasswordPromptProps> = ({ submit }) => {
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");

	const handleSubmit = async (e: any) => {
		e.preventDefault();
		const result = await submit(password);
		if (!result) {
			setError("Incorrect password. Please try again.");
		} else {
			setError("");
		}
	};

	return (
		<div className="gigauser-password-prompt-container">
			<h1 className="gigauser-password-prompt-h1">Knowledge Base Login</h1>
			<form onSubmit={handleSubmit} className="gigauser-password-prompt-form">
				<input
					type="password"
					value={password}
					onChange={(e) => setPassword(e.target.value)}
					className="gigauser-password-prompt-input"
					placeholder="Enter Password"
					aria-label="Password"
				/>
				<button type="submit" className="gigauser-password-prompt-button">
					Submit
				</button>
			</form>
			{error && <div className="gigauser-password-prompt-error">{error}</div>}
		</div>
	);
};

export default PasswordPrompt;
