//For more google voices go to https://cloud.google.com/text-to-speech/docs/voices
// For more flags go to https://www.countryflags.com/

import { Voice } from "../../core/types/guide";
import us from "../../assets/svgs/flag_us";
import uk from "../../assets/svgs/flag_uk";
import aus from "../../assets/svgs/flag_aus";
import ca from "../../assets/svgs/flag_ca";
import ind from "../../assets/svgs/flag_in";
import esp from "../../assets/svgs/flag_esp";
import dk from "../../assets/svgs/flag_dk";
import cn from "../../assets/svgs/flag_cn";
import fr from "../../assets/svgs/flag_fr";
import id from "../../assets/svgs/flag_id";
import de from "../../assets/svgs/flag_de";
import pt from "../../assets/svgs/flag_pt";
import sv from "../../assets/svgs/flag_sv";
import nb from "../../assets/svgs/flag_no";
import ua from "../../assets/svgs/flag_ua";
import it from "../../assets/svgs/flag_it";
import ja from "../../assets/svgs/flag_ja";
import pl from "../../assets/svgs/flag_pl";
import nl from "../../assets/svgs/flag_nl";
import mx from "../../assets/svgs/flag_mx";
import ar from "../../assets/svgs/flag_ar";
import pe from "../../assets/svgs/flag_pe";
import latam from "../../assets/svgs/flag_latam";
import ru from "../../assets/svgs/flag_ru";
import ms from "../../assets/svgs/flag_ms";
import vi from "../../assets/svgs/flag_vi";
import ko from "../../assets/svgs/flag_ko";
import uae from "../../assets/svgs/flag_uae";
import th from "../../assets/svgs/flag_th";
import tr from "../../assets/svgs/flag_tr";
import el from "../../assets/svgs/flag_el";
import hu from "../../assets/svgs/flag_hu";
import fi from "../../assets/svgs/flag_fi";
import is from "../../assets/svgs/flag_is";
import cz from "../../assets/svgs/flag_cz";
import fili from "../../assets/svgs/flag_fili";
import br from "../../assets/svgs/flag_br";

export const filterVoicesByEngine: (
	allVoices: Voice[],
	language: string,
) => {
	premiumVoices: Voice[];
	standardVoices: Voice[];
} = (allVoices, language) => {
	var premiumVoices: Voice[] = [];
	var standardVoices: Voice[] = [];

	const filterByLanguage = allVoices.filter(
		(voice) => voice.language === language,
	);

	premiumVoices = filterByLanguage.filter((voice) => voice.engine !== "google");
	standardVoices = filterByLanguage.filter(
		(voice) => voice.engine === "google",
	);

	return {
		premiumVoices,
		standardVoices,
	};
};

export const filterVoices = (
	voices: Voice[],
	filterTags: string[] | null,
	filterAccents: string[] | null,
	gender: string | null,
) => {
	return voices.filter((v) => {
		filterTags = filterTags?.map((tag) => tag.toLowerCase()) ?? null;
		filterAccents =
			filterAccents?.map((accent) => accent.toLowerCase()) ?? null;

		if (filterTags) {
			if (v.tags) {
				for (let tag of filterTags) {
					if (!v.tags.includes(tag)) return false;
				}
			}
		}

		if (filterAccents) {
			if (v.languageCountryId) {
				const accent = getFlag(v.languageCountryId).name.toLowerCase();
				if (!filterAccents.includes(accent)) return false;
			}
		}

		if (
			gender &&
			v.gender &&
			!(gender.toLowerCase() === v.gender.toLowerCase())
		) {
			return false;
		}

		return true;
	});
};

export const getFlag: (languageCountryId: string) => {
	name: string;
	flag: JSX.Element;
	language?: string;
} = (languageCountryId) => {
	/**
	 * name: accent
	 */

	if (languageCountryId.includes("en")) {
		if (languageCountryId.includes("US"))
			return { name: "American", flag: us(), language: "English" };
		else if (languageCountryId.includes("AU"))
			return { name: "Australian", flag: aus(), language: "English" };
		else if (languageCountryId.includes("GB"))
			return { name: "British", flag: uk(), language: "English" };
		else if (languageCountryId.includes("CA"))
			return { name: "Canadian", flag: ca(), language: "English" };
		else if (languageCountryId.includes("IN"))
			return { name: "Indian", flag: ind(), language: "English" };
		else if (languageCountryId.includes("FR")) {
			return { name: "French", flag: fr(), language: "French" };
		} else if (languageCountryId.includes("DE")) {
			return { name: "German", flag: de(), language: "German" };
		} else return { name: "American", flag: us(), language: "English" };
	} else if (languageCountryId.includes("es")) {
		if (languageCountryId.includes("ES"))
			return { name: "Castillian (Spain)", flag: esp(), language: "Spanish" };
		else if (languageCountryId.includes("419"))
			return { name: "Latam", flag: latam(), language: "Spanish" };
		else if (languageCountryId.includes("MX"))
			return { name: "Mexican", flag: mx(), language: "Spanish" };
		else if (languageCountryId.includes("AR"))
			return { name: "Argentinian", flag: ar(), language: "Spanish" };
		else if (languageCountryId.includes("PE"))
			return { name: "Peruvian", flag: pe(), language: "Spanish" };
		else if (languageCountryId.includes("US"))
			return { name: "American", flag: us(), language: "Spanish" };
		else return { name: "Spanish", flag: esp() };
	} else if (languageCountryId.includes("hi")) {
		return { name: "Hindi", flag: ind() };
	} else if (languageCountryId.includes("ta")) {
		return { name: "Tamil", flag: ind() };
	} else if (languageCountryId.includes("fr")) {
		if (languageCountryId.includes("CA"))
			return { name: "Canadian", flag: ca(), language: "French" };
		return { name: "French", flag: fr() };
	} else if (languageCountryId.includes("da")) {
		return { name: "Danish", flag: dk() };
	} else if (languageCountryId.includes("zh")) {
		return { name: "Chinese", flag: cn() };
	} else if (languageCountryId.includes("id")) {
		return { name: "Indonesian", flag: id() };
	} else if (languageCountryId.includes("de")) {
		return { name: "German", flag: de() };
	} else if (languageCountryId.includes("pt")) {
		if (languageCountryId.includes("BR")) {
			return { name: "Brazil", flag: br() };
		} else if (languageCountryId.includes("PT")) {
			return { name: "Portugal", flag: pt() };
		}
		return { name: "Brazil", flag: br() };
	} else if (languageCountryId.includes("sv")) {
		return { name: "Swedish", flag: sv() };
	} else if (
		languageCountryId.includes("no") ||
		languageCountryId.includes("nb")
	) {
		return { name: "Norwegian", flag: nb() };
	} else if (languageCountryId.includes("uk")) {
		return { name: "Ukrainian", flag: ua() };
	} else if (languageCountryId.includes("ja")) {
		return { name: "Japanese", flag: ja() };
	} else if (languageCountryId.includes("it")) {
		return { name: "Italian", flag: it() };
	} else if (languageCountryId.includes("pl")) {
		return { name: "Polish", flag: pl() };
	} else if (languageCountryId.includes("it")) {
		return { name: "Italian", flag: it() };
	} else if (languageCountryId.includes("nl")) {
		return { name: "Dutch", flag: nl() };
	} else if (languageCountryId.includes("ru")) {
		return { name: "Russian", flag: ru() };
	} else if (languageCountryId.includes("ms")) {
		return { name: "Malaysian", flag: ms() };
	} else if (languageCountryId.includes("vi")) {
		return { name: "Vietnamese", flag: vi() };
	} else if (languageCountryId.includes("ko")) {
		return { name: "Korean", flag: ko() };
	} else if (languageCountryId.includes("ar")) {
		return { name: "Arabic", flag: uae() };
	} else if (languageCountryId.includes("bn")) {
		return { name: "Bengali", flag: ind() };
	} else if (languageCountryId.includes("cs")) {
		return { name: "Czech", flag: cz() };
	} else if (languageCountryId.includes("fil")) {
		return { name: "Filipino", flag: fili() };
	} else if (languageCountryId.includes("fi")) {
		return { name: "Finnish", flag: fi() };
	} else if (languageCountryId.includes("el")) {
		return { name: "Greek", flag: el() };
	} else if (languageCountryId.includes("gu")) {
		return { name: "Gujarati", flag: ind() };
	} else if (languageCountryId.includes("he")) {
		return { name: "Hebrew", flag: is() };
	} else if (languageCountryId.includes("hu")) {
		return { name: "Hungarian", flag: hu() };
	} else if (languageCountryId.includes("kn")) {
		return { name: "Kannada", flag: ind() };
	} else if (languageCountryId.includes("ml")) {
		return { name: "Malayalam", flag: ind() };
	} else if (languageCountryId.includes("mr")) {
		return { name: "Marathi", flag: ind() };
	} else if (languageCountryId.includes("pa")) {
		return { name: "Punjabi", flag: ind() };
	} else if (languageCountryId.includes("te")) {
		return { name: "Telugu", flag: ind() };
	} else if (languageCountryId.includes("th")) {
		return { name: "Thai", flag: th() };
	} else if (languageCountryId.includes("tr")) {
		return { name: "Turkish", flag: tr() };
	} else return { name: "English", flag: us() };
};

export const getModel = (voice: Voice) => {
	if (voice.model == "eleven_multilingual_v1") {
		return "gen 1.5";
	} else if (voice.model == "eleven_multilingual_v2") {
		return "gen 2";
	} else if (voice.model == "eleven_turbo_v2") {
		return "gen 3";
	} else if (voice.engine == "google") {
		return "gen 1";
	} else {
		return "gen 2";
	}
};
