import React, { useContext } from "react";
import playIcon from "../../../../../assets/svgs/playIcon";
import Icon from "../../../../../ui/Icon/Icon";

import "./CanvasControls.css";
import volmax from "../../../../../assets/svgs/canvas/volumeMaxIcon";

import {
	Slider,
	SliderTrack,
	SliderFilledTrack,
} from "@chakra-ui/react";
import fullScreenIcon from "../../../../../assets/svgs/canvas/fullScreenIcon";
import CanvasPlayerContext from "../../../CanvasPlayerContext";
import { formatTime } from "../../../canvasUtils/videoUtils";
import pauseIcon from "../../../../../assets/svgs/pauseIcon";
import volmid from "../../../../../assets/svgs/canvas/volumeMidIcon";
import volmin from "../../../../../assets/svgs/canvas/volumeMinIcon";
import minimizeScreenIcon from "../../../../../assets/svgs/canvas/minimizeScreenIcon";
import { Device } from "../../../../../types/devices";
import useWindowSize from "../../../../../hooks/useWindowSize";
import { CanvasPlayerControllerEnvironment } from "../../CanvasPlayerController";

type CanvasControlsProps = {
	volume: number;
	setVolume: (level: number) => void;
	toggleFullscreen: () => void;

	customPlayHandler?: () => void;
	customPauseHandler?: () => void;
	environment: CanvasPlayerControllerEnvironment;
	overrideDevice?: Device;

	hideFullscreen?: boolean;
};

const CanvasControls: React.FC<CanvasControlsProps> = (props) => {
	const cp = useContext(CanvasPlayerContext);

	var device: Device = useWindowSize().device;
	if (props.overrideDevice) device = props.overrideDevice;

	if (!cp.vidRef) throw new Error("Missing video ref in CanvasControls!");

	const formattedCurrTime = formatTime(cp.currentTime);
	const formattedTotalTime = formatTime(cp.getVideoDuration());

	const onChangeVolume = (value: number) => {
		props.setVolume(value / 100);
	};

	const playHandler = props.customPlayHandler
		? props.customPlayHandler
		: cp.play;
	const pauseHandler = props.customPauseHandler
		? props.customPauseHandler
		: cp.pause;

	return (
		<div className="gigauser-canvas-controls-buttons">
			<div className="gigauser-canvas-controls-left">
				<Icon
					className="gigauser-canvas-control-play"
					onClick={cp.paused ? playHandler : pauseHandler}
				>
					{cp.paused ? playIcon("white") : pauseIcon("white")}
				</Icon>

				<div
					className="gigauser-canvas-volume-container"
					style={{ display: device === "mobile" ? "none" : undefined }}
				>
					<Icon className="gigauser-canvas-control-button gigauser-canvas-volume">
						{props.volume > 0.5
							? volmax("white")
							: props.volume > 0
								? volmid("white")
								: volmin("white")}
					</Icon>

					<div className="gigauser-canvas-volume-slider">
						<Slider
							aria-label="slider-ex-1"
							className="gigauser-volume-slider-chakra"
							defaultValue={60}
							value={props.volume * 100}
							min={0}
							max={100}
							onChange={onChangeVolume}
							colorScheme="white"
						>
							<SliderTrack
								bg={"#ffffff80"}
								className="gigauser-volume-slidertrack"
							>
								<SliderFilledTrack bg={"white"} />
							</SliderTrack>
						</Slider>
					</div>
				</div>

				<div className="gigauser-canvas-time-display">
					<div className="gigauser-canvas-time-item">
						{formattedCurrTime.minutes.charAt(0)}
					</div>
					<div className="gigauser-canvas-time-item">
						{formattedCurrTime.minutes.charAt(1)}
					</div>
					:
					<div className="gigauser-canvas-time-item">
						{formattedCurrTime.seconds.charAt(0)}
					</div>
					<div className="gigauser-canvas-time-item">
						{formattedCurrTime.seconds.charAt(1)}
					</div>
					<span className="gigauser-canvas-time-slash">/</span>
					<div className="gigauser-canvas-time-item">
						{formattedTotalTime.minutes.charAt(0)}
					</div>
					<div className="gigauser-canvas-time-item">
						{formattedTotalTime.minutes.charAt(1)}
					</div>
					:
					<div className="gigauser-canvas-time-item">
						{formattedTotalTime.seconds.charAt(0)}
					</div>
					<div className="gigauser-canvas-time-item">
						{formattedTotalTime.seconds.charAt(1)}
					</div>
				</div>
			</div>

			<div className="gigauser-canvas-controls-right">
				{/* <Icon className="gigauser-canvas-control-button">{ccUnfilled("white")}</Icon> */}
				{!props.hideFullscreen ? (
					<Icon
						className="gigauser-canvas-control-button"
						onClick={props.toggleFullscreen}
					>
						{!cp.fullscreen
							? fullScreenIcon("white")
							: minimizeScreenIcon("white")}
					</Icon>
				) : null}
			</div>
		</div>
	);
};
export default CanvasControls;
