import {
	Guide,
	GuideData,
	GuidePreview,
} from "@gigauser/common/src/core/types/guide";
import { useContext } from "react";
import ExtHelpCenterContext from "../templates/v2/ExtHelpCenterContext";
import { useAppDispatch, useAppSelector } from "../redux";
import { setCollectionPath, setCurrentGuide } from "../redux/slices/hcSlice";
import { captureEvent } from "@gigauser/common/src/core/analytics/analytics";
import useNavigateToLink from "./useNavigateToLink";
import { Collection } from "@gigauser/common/src/core/types/collections";
import { GigaUserApi } from "@giga-user-fern/api";
import { rootCollection } from "@gigauser/common/src/types/files";
import useScrollTop from "./useScrollTop";
const useOpenGuideFromId: () => (
	guideId: GigaUserApi.Id,
) => Promise<Guide | null> = () => {
	const helpCenterContext = useContext(ExtHelpCenterContext);
	const dispatch = useAppDispatch();
	const navigateToLink = useNavigateToLink();
	const scrollTop = useScrollTop();

	return async (guideId) => {
		//This is to set guide loading.
		// dispatch(setCurrentGuide({
		//     id: guidePreview.id,
		//     guidePreview: guidePreview
		// }))
		dispatch(
			setCurrentGuide({
				id: guideId,
			}),
		);
		const preview = await helpCenterContext.fetchGuidePreview(guideId);
		// const videoEdits= await helpCenterContext.getVideoEdits(guideId)

		if (!preview) return null;
		dispatch(
			setCurrentGuide({
				id: preview.id,
				guidePreview: preview,
				// videoEdits: videoEdits || undefined
			}),
		);
		const dataPromise = helpCenterContext.fetchGuideData(guideId);
		let segmentsPromise;
		if (preview.parentId) {
			segmentsPromise = helpCenterContext.getPath(preview.parentId);
		} else {
			segmentsPromise = Promise.resolve([rootCollection] as [Collection]);
		}

		const [data, segments] = await Promise.all([dataPromise, segmentsPromise]);
		if (preview.isPinned) {
			dispatch(setCurrentGuide(null));
			if (segments) {
				navigateToLink("/folders/" + segments.at(-1)?.id);
				dispatch(setCollectionPath(segments));
			} else {
				console.error("SEGMENTS FAILED", segments);
			}

			return null;
		}
		dispatch(
			setCurrentGuide({
				id: guideId,
				guidePreview: preview,
				guideData: data!,
				// videoEdits: videoEdits
			}),
		);

		if (segments) dispatch(setCollectionPath(segments));
		else {
			console.error("SEGMENTS FAILED", segments);
		}

		captureEvent({
			eventName: "GuideOpenedInHelpCenter",
			value: { guide_id: guideId },
		});

		scrollTop();
		navigateToLink("/guide/" + guideId);
		return {
			id: guideId,
			guidePreview: preview,
			guideData: data!,
		};
	};
};

export default useOpenGuideFromId;
