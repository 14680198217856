import React, { useRef, useState } from "react";
import "./TitledInput.css";
import logger from "../../../utils/logger";
import { Size } from "../../../types/sizes";
import { Flex } from "@chakra-ui/react";
import downArrowheadIcon from "../../../assets/svgs/downArrowheadIcon";
import Icon from "../../Icon/Icon";
import Clickout from "../../../layouts/Clickout/Clickout";

export type TitledInputOption = {
	icon?: string | React.ReactElement; //(must be an emoji string, not a filepath)
	text: string;
};

export type TitledInputProps = {
	id: string;
	placeHolder?: string;
	label?: string;
	icon?: string; //file path to an image
	dims?: Size;
	options?: TitledInputOption[];
	edges?: "sharp" | "round";
	onChange: (e: React.FormEvent<HTMLInputElement> | dummyEvent) => void;
	value: string;
	type?: string; //text, email, password, etc.
	position?: "default" | "full" | "right half" | "left half";
	styleSize?: string;
	error?: boolean | string; //(if true, the input box will be in error style but no message will be shown)
	onEnter?: () => void; //(function that runs when enter key is pressed),
	display?: boolean; //(false if the box should be hidden)
	className?: string; //classNames appended to the topmost div. Can be used to edit width, margin, etc.
	background?: string;
} & React.HTMLProps<HTMLInputElement>; //Any more props allowed by input are allowed

type dummyEvent = {
	target: {
		id: string;
		value: string;
	};
};

const TitledInput: React.FC<TitledInputProps> = ({
	placeHolder,
	label,
	icon,
	options,
	id,
	onChange,
	value,
	type = "text",
	position = "full",
	styleSize = "normal",
	error,
	display = true,
	...props
}) => {
	/**
	 * display?: boolean (false if the box should be hidden)
	 */

	const [focussed, setFocus] = useState(false);

	const [showOptions, setShowOptions] = useState(false);

	const getInputWidthClass = () => {
		if (icon && options) return "two-icons";
		else if (icon || options) return "one-icon";
		else return "no-icon";
	};

	const inputOnChange = (e: React.FormEvent<HTMLInputElement>) => {
		onChange(e);
	};

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key == "Enter") {
			if (props.onEnter) props.onEnter();
			e.preventDefault();
		}
	};

	const setOption = (option: string) => {
		// setValue(option)
		const dummyEvent = {
			target: {
				id: id,
				value: option,
			},
		};
		onChange(dummyEvent);
		setShowOptions(false);
	};

	if (display)
		return (
			<div
				className={`TitledInput thin-scroll ${error ? "error-input" : ""} ${position} ${props.className} ${options ? "titled-dropdown" : ""}`}
			>
				<Clickout
					closeFunction={() => {
						setShowOptions(false);
					}}
				>
					<div
						onClick={
							options
								? () => {
										setShowOptions(true);
									}
								: undefined
						}
						className={`titledinput-box input-main-container 
                        ${props.disabled ? "disabled" : "not-disabled"} 
                        ${options ? "titled-dropdown" : ""} ${props.edges} ${focussed ? "active" : ""}`}
					>
						{label ? (
							<span className={`input-label ${focussed ? "" : "active"}`}>
								{label}
							</span>
						) : null}
						{icon ? <img src={icon} className="input-icon"></img> : null}
						<input
							value={value}
							onChange={options ? undefined : inputOnChange}
							className={`titledInput ${options ? "dropdown-input" : ""}  ${getInputWidthClass()} ${styleSize} ${props.dims} `}
							id={id}
							onFocus={() => setFocus(true)}
							onBlur={() => setFocus(false)}
							type={type}
							autoComplete={props.autoComplete}
							onKeyDown={props.onEnter ? inputOnKeyDown : undefined}
							placeholder={placeHolder}
							{...props}
						/>
						{options ? (
							<Icon className="input-icon dropdown-icon">
								{downArrowheadIcon()}
							</Icon>
						) : null}
					</div>
					{typeof error === "string" ? (
						<div className="error-message">{error}</div>
					) : null}
					{options ? (
						<div
							className="titledinput-options-container slide-in-down "
							style={{ display: showOptions ? "block" : "none" }}
						>
							<div
								className="options-scroll"
								style={{ visibility: showOptions ? "visible" : "hidden" }}
							>
								{options.map((option) => (
									<div
										className={`titledinput-option ${props.size}`}
										key={option.text}
										id={option.text}
										onClick={() => {
											setOption(option.text);
										}}
									>
										<Flex gap={"0.75rem"} alignItems={"center"}>
											<div className="icon">{option.icon}</div>
											<div className="text">{option.text}</div>
										</Flex>
									</div>
								))}
							</div>
						</div>
					) : null}
				</Clickout>
			</div>
		);
	else return null;
};

export default React.memo(TitledInput);
