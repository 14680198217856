import React, { useContext, useEffect } from "react";
import HelpCenterHome from "./HelpCenterHome/main/HelpCenterHome";
import {
	HelpCenterProps,
	Navigate,
} from "@gigauser/common/src/help-center/HelpCenter/HelpCenter";
import logger from "@gigauser/common/src/utils/logger";
import { isProdDomain } from "@gigauser/common/src/help-center/utils/routeUtils";
import { HelpCenterPage } from "../../App";
import HelpCenterBrowser from "./HelpCenterBrowser/main/HelpCenterBrowser";
import ExtHelpCenterContext from "./ExtHelpCenterContext";
import HelpCenterSearchBox from "./HelpCenterSearchBox/HelpCenterSearchBox";
import { useAppSelector } from "../../redux";
import HelpCenterUnlisted from "./HelpCenterUnlisted/HelpCenterUnlisted";
import { HelpCenterConfig } from "@giga-user-fern/api/types/api";
import orgSetupData from "@gigauser/common/src/setup/orgSetupData";
import { saver } from "@gigauser/common/src/network/saver";

type ExtHelpCenterProps = {
	page: HelpCenterPage;
	helpCenterConfig: HelpCenterConfig
};

const ExtHelpCenter: React.FC<ExtHelpCenterProps> = (props) => {


	var navigate: Navigate | undefined;

	const helpCenterContext = useContext(ExtHelpCenterContext);

	if (helpCenterContext.navigator) {
		navigate = helpCenterContext.navigator();
	}

	const isSearchVisible = useAppSelector((state) => state.hcUi.searchVisible);

	const navigateToLink = (string: string) => {
		logger.debug("time to navigate to link: ", string, process.env);

		if (navigate) {
			const newURL =
				string +
				(!isProdDomain()
					? `?org=${helpCenterContext.getOrganization()?.id}`
					: "");
			logger.debug("newURL=", newURL);
			navigate(newURL);
		}
	};

	const theme = useAppSelector((state) => state.hcUi.theme);

	return (
		<div className={`ExtHelpCenter-wrapper ${theme}`}>
			{isSearchVisible ? <HelpCenterSearchBox page={props.page} /> : null}
			{props.page === "Browser" ? (
				<HelpCenterBrowser helpCenterConfig={props.helpCenterConfig} />
			) : props.page === "Home" ? (
				<HelpCenterHome />
			) : (
				<HelpCenterBrowser helpCenterConfig={props.helpCenterConfig} viewOnly />
			)}
		</div>
	);
};
export default ExtHelpCenter;
