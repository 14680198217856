import React, {
	FC,
	forwardRef,
	PropsWithChildren,
	useCallback,
	useEffect,
	useImperativeHandle,
} from "react";
import {
	LinkClickData,
	LinkExtension,
	PlaceholderExtension,
} from "remirror/extensions";
import { TableExtension } from "@remirror/extension-react-tables";
import {
	EditorComponent,
	Remirror,
	TableComponents,
	ThemeProvider,
	useActive,
	useChainedCommands,
	useCommands,
	useEditorView,
	useRemirror,
	useRemirrorContext,
} from "@remirror/react";
import { AllStyledComponent } from "@remirror/styles/emotion";

import { BubbleMenu } from "./components/BubbleMenu";

import {
	CreateEditorStateProps,
	Extension,
	getDefaultDocNode,
	InvalidContentHandler,
	RemirrorContentType,
	RemirrorJSON,
	schemaToJSON,
} from "remirror";
import type { ReactFrameworkOutput, RemirrorProps } from "@remirror/react";
import "remirror/styles/all.css";
import "./TextEditor.css";
import { getExtensions } from "./utils/getExtensions";
import { OnChangeJSON } from "@remirror/react";
import { DOMSerializer } from "prosemirror-model";
import {
	NavigateToTimeFunction,
	SpecialImageExtension,
} from "./extensions/SpecialImageExtension";
import { PlainDoc } from "../../../core/types/guide";
import { cleanJSON, refillImages } from "./utils/cleanImageSrc";
import RichTextToolbar, {
	VoiceProps,
} from "./components/RichTextToolbar/RichTextToolbar";
import { GigaUserApi } from "@giga-user-fern/api";
import logger from "../../../utils/logger";
import { Size } from "../../../types/sizes";
import { useEffectOnce } from "../../../hooks/useEffectOnce";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { openOverlay } from "../../../layouts/Overlay";
import LinkPopup from "./components/RichTextToolbar/LinkPopup/LinkPopup";
import { useSelectedText } from "@remirror/react";
import { Id } from "@giga-user-fern/api/types/api";
import { ViewingLinkExtension } from "./extensions/ViewingLinkExtension";
import useEditScreenshot, {
	ConvertToGifFunction,
} from "./components/EditScreenshot/useEditScreenshot";
import { Crop } from "@giga-user-fern/api/types/api/resources/video";
import { selectGuidePreview } from "../../../redux/slices/guideSlice";

export type TextEditorType =
	| "extension"
	| "platform"
	| "videoTranscript"
	| "helpCenter";

export interface ReactEditorProps
	extends Pick<CreateEditorStateProps, "stringHandler">,
		Pick<RemirrorProps, "initialContent" | "editable" | "autoFocus" | "hooks"> {
	version: string;
	type: TextEditorType;
	placeholder?: string;
	save?: (x: RemirrorJSON) => void;
	onEnhance?: () => void;
	onGenerate?: () => void;
	scrollContainerRef: React.MutableRefObject<HTMLDivElement | null>;
	fixToolbar?: boolean;
	size?: Size;
	appTheme: "gigauser-light" | "gigauser-dark";
	onOpenGuideFromId?: (x: Id) => void;
	enhanceLoading?: boolean;
	generateLoading?: boolean;
	convertToGif?: ConvertToGifFunction;
	navigateToTime?: NavigateToTimeFunction;
}

export interface TextEditorProps extends Partial<ReactEditorProps> {}
export type RichTextData = any;

//forwardRef is if you want to control externally. (via parent)

export const TextEditor = forwardRef<
	ReactFrameworkOutput<Extension> | undefined,
	PropsWithChildren<TextEditorProps> & (VoiceProps | {})
>(
	(
		{
			placeholder,
			stringHandler,
			children,
			version,
			type,
			onEnhance,
			onGenerate,
			save,
			scrollContainerRef,
			appTheme,
			onOpenGuideFromId,
			convertToGif,
			navigateToTime,
			...rest
		},
		ref,
	) => {
		//@ts-ignore
		const editScreenshot = useEditScreenshot(type);
		//@ts-ignore
		const linkExtension =
			type == "helpCenter"
				? new ViewingLinkExtension({
						onOpenGuideFromId: (x) => {
							if (onOpenGuideFromId) {
								onOpenGuideFromId(x);
							} else {
								return false;
							}
							return true;
						},
					})
				: new LinkExtension({
						autoLink: true,
						defaultTarget: "_blank",
						extraAttributes: {
							guideId: {
								default: null,
								parseDOM: (dom) => dom.getAttribute("data-guide-id"),
								toDOM: (attrs) => ["data-guide-id", attrs.guideId as any],
							},
						},
					});

		const proxyConvertToGif: ConvertToGifFunction = () => {};
		
		const extensions = useCallback(
			() => [
				new PlaceholderExtension({ placeholder: "Click here to begin typing" }), //want
				new TableExtension(), //dont want
				new SpecialImageExtension({
					//dont want
					enableResizing: false,
					editable: rest.editable,
					type: type ?? "platform",
					editScreenshot: editScreenshot,
					convertToGif: convertToGif || proxyConvertToGif,
					navigateToTime: navigateToTime,
				}),
				linkExtension,

				...getExtensions({ syntaxTheme: "atom_dark" }),
				//use the default extensions in getExtensions These are the functionalities of the editor. Maybe make a copy.

				//(appTheme?? "gigauser-light") === "gigauser-light" ? "vs" : "atom_dark"
			],
			[rest.editable],
		);

		const { manager, getContext } = useRemirror({ extensions, stringHandler });
		//@ts-ignore
		useImperativeHandle(ref, () => getContext(), [getContext]); //not needed. external.

		const onChange = useCallback((json: RemirrorJSON) => {
			//function run when the user makes some edits.
			// Store the JSON in localStorage
			save?.(json); //probably do some redux dispatch here for text slide. not a well defined type.
			//TODO: Optionally call the flag stuff here.
		}, []);

		const onError: InvalidContentHandler = useCallback(
			({ json, invalidContent, transformers }) => {
				// Automatically remove all invalid nodes and marks.
				logger.debug("INVALID", json, invalidContent);
				return transformers.remove(json, invalidContent);
			},
			[],
		);

		var voiceProps = {};

		if ("voice" in rest) {
			voiceProps = { voice: rest.voice, onClickVoice: rest.onClickVoice };
		}
		//TODO: Remirror manager is important. Anything within that can modify and access and run toolbar.
		return (
			<div className={`TextEditorContainer ${appTheme}`}>
				<ThemeProvider>
					{
						//@ts-ignore
					} <Remirror manager={manager} {...rest} onError={onError} >
						{/* <center><button onClick={onCopy}>Copy</button></center> */}
						<div
							className={`gigauser-TextEditor ${rest.size} ${rest.fixToolbar ? "gigauser-text-editor-fix" : ""}`}
						>
							{type !== "helpCenter" ? (
								<>
									<RichTextToolbar
										textEditorType={type || "platform"}
										save={save}
										onEnhance={onEnhance}
										enhanceLoading={rest.enhanceLoading}
										generateLoading={rest.generateLoading}
										onGenerate={onGenerate}
										version={version ?? "2023-03-12"}
										scrollContainerRef={scrollContainerRef}
										fixToolbar={rest.fixToolbar}
										{...voiceProps}
										disabled={!rest.editable}
									/>
								</>
							) : null}

							{type != "helpCenter" && (
								<FakeLinkComponent
									linkExtension={linkExtension as LinkExtension}
									editable={rest.editable as boolean}
								></FakeLinkComponent>
							)}
							{/* {rest.editable
                    ? <SetContentButton
                          onEnhance=
                              {onEnhance ??
                              function (x: PlainDoc) {
                                  return Promise.resolve(x);
                              }}
                          version= {version ?? "2023-03-12"}
                      />
                    : null}
                {rest.editable? <UndoButton></UndoButton>:null}
                {rest.editable? <RedoButton></RedoButton>:null} */}

							<EditorComponent />
							{/* {rest.editable ? <BubbleMenu /> : null} */}
							<OnChangeJSON onChange={onChange}></OnChangeJSON>
							{/* <TableComponents /> */}
							{children}
						</div>
					</Remirror>
				</ThemeProvider>
			</div>
		);
	},
);

const FakeLinkComponent = (props: {
	linkExtension: LinkExtension;
	editable: boolean;
}) => {
	const commands = useChainedCommands();
	const dispatch = useAppDispatch();
	const view = useEditorView();
	useEffectOnce(() => {
		props.linkExtension.addHandler("onClick", (e, data) => {
			// We need to check for if editable
			// e.preventDefault()
			// e.stopPropagation()
			let href = data.href;
			if (data.guideId as any) {
				e.preventDefault();
				e.stopPropagation();
				href = "/guide/" + data.guideId;
			}
			if (!props.editable) {
				// Just take them to that page
				if (data.guideId) {
					window.open(href, "_blank");
				}
				return false;
			} else {
				// Give them a way to edit
				// i'm going to use confirm for now because
				// why not
				commands.selectLink().run();
				const text = view.state.doc.textBetween(
					view.state.selection.from,
					view.state.selection.to,
				);

				dispatch(
					openOverlay({
						heading: {
							icon: null,
							title: "Link Details",
							data: "",
						},
						component: (
							<LinkPopup
								text={text}
								currentLink={(data.guideId as any) ?? data.href}
								save={(newText, newLink) => {
									if (newLink) {
										if (newLink.includes("https"))
											commands
												.replaceText({ content: newText })
												.updateLink({ href: newLink })
												.run();
										else
											commands
												.replaceText({ content: newText })
												.updateLink({ href: "#0", guideId: newLink })
												.run();
									}
								}}
								delete={() => commands.removeLink().run()}
							></LinkPopup>
						),
					}),
				);
				// const newLink = prompt("Enter link value or guide ID")
				// if (newLink){

				//     if (newLink.includes("https")) commands.selectLink().updateLink({href: newLink}).run()
				//     else commands.selectLink().updateLink({href: '', guideId: newLink}).run()
				// }
			}
			return false;
		});
	});
	return <></>;
};
