import { useState, useEffect } from "react";
import { Device } from "../types/devices";

type windowSizeType = {
	width: number;
	height: number;
};

const useWindowSize: () => { device: Device; height: number; width: number } =
	() => {
		const [windowSize, setWindowSize] = useState<windowSizeType>({
			width: window.innerWidth,
			height: window.innerHeight,
		});

		const changeWindowSize = () => {
			setWindowSize({ width: window.innerWidth, height: window.innerHeight });
		};

		useEffect(() => {
			window.addEventListener("resize", changeWindowSize);

			return () => {
				window.removeEventListener("resize", changeWindowSize);
			};
		}, []);

		return {
			device: windowSize.width > 500 ? "laptop" : "mobile",
			height: windowSize.height,
			width: windowSize.width,
		};
	};

export default useWindowSize;
