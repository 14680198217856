import { GigaUserApi } from "@giga-user-fern/api";
import { Collection } from "../../core/types/collections";
import { rootCollection } from "../../types/files";
import { GuideData, GuidePreview } from "../../core/types/guide";
import logger from "../../utils/logger";

type GetPathFunction = (
	id: GigaUserApi.Id,
) => Promise<false | [Collection, ...Collection[]]>;
type FetchGuidePreviewFunction = (
	id: GigaUserApi.Id,
) => Promise<GuidePreview | null>;

type GetPathFromRouteProps = {
	getPath: GetPathFunction;
	fetchGuidePreview: FetchGuidePreviewFunction;
	onLoadingGuide?: () => void;
	onLoadingCollection?: () => void;
};

export const isProdDomain = () => {
	return true;
	if (
		window.location.hostname !== "localhost" &&
		window.location.hostname !== "preview.clueso.io"
	) {
		return true;
	}
};

const getConstructedPath = async (
	collectionId: string,
	getPath: GetPathFunction,
) => {
	var newPath: [Collection, ...Collection[]] = [rootCollection];

	if (collectionId === "Collection_root") {
		newPath = [rootCollection];
	} else if (collectionId) {
		const sections = await getPath(GigaUserApi.Id(collectionId));
		if (sections) {
			logger.debug("getPath sections: ", sections);
			newPath = sections as [Collection, ...Collection[]];
		} else {
			logger.debug("no sections");
		}
	}

	return newPath;
};

type CollectionPathRoute = {
	type: "folders";
	newPath: [Collection, ...Collection[]];
};

type GuidePathRoute = {
	type: "guide";
	guidePreview: GigaUserApi.guides.GuidePreview;
	newPath: [Collection, ...Collection[]];
};

export const getPathFromRoute: (
	props: GetPathFromRouteProps,
) => Promise<false | undefined | CollectionPathRoute | GuidePathRoute> = async (
	props,
) => {
	const urlSegments = window.location.pathname
		.split("/")
		.filter((x) => x !== "");

	const processCollectionPathRoute: (
		collectionId?: string,
	) => Promise<CollectionPathRoute> = async (collectionId) => {
		if (props.onLoadingCollection) props.onLoadingCollection();

		//Folders

		if (collectionId == undefined) {
			//collectionId is not passed as a param
			collectionId = urlSegments.at(-1);
			if (!collectionId) collectionId = "";
		}

		const newPath = await getConstructedPath(collectionId, props.getPath);

		return {
			type: "folders",
			newPath,
		};
	};

	try {
		if (urlSegments.includes("folders")) {
			//Folders
			const res = await processCollectionPathRoute();
			return res;
		} else if (
			urlSegments.includes("guide") ||
			urlSegments.includes("share") ||
			urlSegments.includes("embed")
		) {
			logger.debug("SEGMENTS IS", urlSegments);
			if (props.onLoadingGuide) props.onLoadingGuide();

			const guideId = urlSegments.at(-1);

			if (!guideId) return false;

			const guidePreview = (await props.fetchGuidePreview(
				GigaUserApi.Id(guideId),
			)) as GuidePreview;

			let collectionId = guidePreview.parentId?.toString();
			if (!collectionId) collectionId = "";

			if (guidePreview.isPinned) {
				//it's a pinned guide. We need to redirect to collection.
				const res = await processCollectionPathRoute(
					guidePreview.parentId || "",
				);
				return res;
			} else {
				const newPath = await getConstructedPath(collectionId, props.getPath);

				return {
					type: "guide",
					guidePreview,
					newPath,
				};
			}
		}
	} catch (e) {
		logger.error(e);
		return false;
	}
};
