const f = (color = "#000000") => {
	return (
		<svg
			height="100%"
			width="100%"
			version="1.1"
			id="Capa_1"
			viewBox="0 0 473.68 473.68"
			fill="#000000"
		>
			<g id="SVGRepo_bgCarrier" stroke-width="0" />

			<g
				id="SVGRepo_tracerCarrier"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>

			<g id="SVGRepo_iconCarrier">
				{" "}
				<circle fill="#FFFFFF" cx="236.85" cy="236.85" r="236.83" />{" "}
				<path
					fill="#efefef"
					d="M460.14,157.877H314.219c6.335,50.593,6.376,106.339,0.12,156.995h146.116 c8.526-24.442,13.219-50.682,13.219-78.03C473.678,209.143,468.876,182.577,460.14,157.877z"
				/>{" "}
				<path
					fill="#e73b35"
					d="M314.219,157.877h145.922c-0.026-0.075-0.049-0.138-0.075-0.206 C429.753,72.204,351.786,9.323,258.105,0.976C294.358,20.848,304.944,83.808,314.219,157.877z"
				/>{" "}
				<path
					fill="#3757a6"
					d="M258.109,472.701c93.848-8.362,171.927-71.46,202.12-157.156c0.079-0.228,0.146-0.452,0.228-0.673 h-146.12C305.143,389.342,294.51,452.746,258.109,472.701z"
				/>{" "}
				<path
					fill="#EFEFEF"
					d="M0.001,236.842c0,27.348,4.693,53.588,13.219,78.03h313.313c6.256-50.66,6.215-106.402-0.12-156.995 H13.534C4.798,182.577,0.001,209.143,0.001,236.842z"
				/>{" "}
				<path
					fill="#e73b35"
					d="M13.604,157.672c-0.026,0.067-0.049,0.131-0.075,0.206h312.883 c-9.274-74.07-32.056-137.029-68.303-156.901C251.098,0.355,244.007,0,236.835,0C133.807,0,46.185,65.805,13.604,157.672z"
				/>{" "}
				<path
					fill="#3757A6"
					d="M326.533,314.872H13.22c0.079,0.221,0.15,0.445,0.228,0.673 C45.9,407.647,133.637,473.68,236.835,473.68c7.173,0,14.263-0.352,21.274-0.98C294.51,452.746,317.337,389.342,326.533,314.872z"
				/>{" "}
			</g>
		</svg>
	);
};
export default f;
